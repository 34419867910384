import { Form, Select } from "antd";
import { FormattedMessage } from "react-intl";
import { Translation } from "../../utils";
import { Required } from "../../utils/Forms";

export default function SelectInput(props: any) {
  const {
    labelName,
    name,
    isRequired,
    loading,
    list,
    mapValue,
    mapName,
    className,
  } = props;

  return (
    <div className={className || "row"}>
      <Form.Item
        rules={[{ required: isRequired, message: Required(labelName) }]}
        label={<FormattedMessage id={labelName} />}
        name={name}
        className="hp-mb-0"
      >
        <Select
          placeholder={`${Translation("choose")} ${Translation(labelName)}`}
          disabled={loading}
          className="hp-w-100"
          size="large"
        >
          {list.map((item: any) => {
            return (
              <Select.Option value={item[mapValue]} key={item[mapValue]}>
                {item[mapName]}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );
}
