import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export default function PageAddButtons({ loading, formHanlder, link }: any) {
  return (
    <div className="hp-d-flex hp-justify-content-end">
      <Link to={link}>
        <Button
          type="primary"
          disabled={loading}
          className="hp-bg-black-0 hp-border-color-black-40 hp-text-color-black-100 hp-px-20 hp-mr-12"
        >
          <FormattedMessage id="cancel" />
        </Button>
      </Link>

      <Button
        disabled={loading}
        type="primary"
        className="hp-bg-primary-1 hp-border-color-primary-1 hp-px-20"
        onClick={formHanlder}
        loading={loading}
      >
        <FormattedMessage id="add" />
      </Button>
    </div>
  );
}
