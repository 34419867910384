export default function InfoIcon(props: any) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8ZM7.30413 7.03893C8.06833 6.65689 8.92873 7.34707 8.72153 8.17593L8.24887 10.0666L8.27653 10.0528C8.52347 9.92933 8.8238 10.0294 8.94733 10.2764C9.0708 10.5234 8.97073 10.8237 8.72373 10.9472L8.69607 10.9611C7.93193 11.3431 7.07147 10.6529 7.27873 9.82407L7.7514 7.9334L7.72373 7.9472C7.47673 8.07073 7.1764 7.9706 7.05287 7.7236C6.9294 7.4766 7.02953 7.17627 7.27653 7.0528L7.30413 7.03893ZM8 6C8.27613 6 8.5 5.77614 8.5 5.5C8.5 5.22386 8.27613 5 8 5C7.72387 5 7.5 5.22386 7.5 5.5C7.5 5.77614 7.72387 6 8 6Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
