import { AvailabilitiesProps, Hour } from "./availability";
import { useAppContext } from "../../../context/appState";
import { FC, useEffect } from "react";

type Props = {
  availabilities: AvailabilitiesProps;
  setAvailabilities: (data: AvailabilitiesProps) => void;
  day: string;
  hour: Hour;
  index: number;
  initialValues?: any;
  onSelect?: any;
  disabled?: boolean | null;
  isUpdate?: boolean;
};

const AvailabilityTime: FC<Props> = ({
  availabilities,
  setAvailabilities,
  day,
  hour,
  index,
  isUpdate,
  initialValues,
  onSelect,
  disabled,
}) => {
  const { contextState, setContextState } = useAppContext();
  useEffect(() => {
    if (isUpdate) {
      onSelect(initialValues);
    }
  }, [isUpdate, initialValues]);

  useEffect(() => {
    setContextState({
      ...contextState,
      availabilitySelected: [...initialValues],
    });
  }, []);

  useEffect(() => {
    const newAvailabilities: AvailabilitiesProps = availabilities.map(
      (week, weekIndex) => {
        return {
          ...week,
          hours: week.hours.map((hour: Hour, hourIndex) => {
            const initAvailabilities = initialValues.find(
              (availability: any) => {
                return (
                  availability.dayOfTheWeek === weekIndex &&
                  availability.startTime === hour.startTime &&
                  availability.endTime === hour.endTime
                );
              }
            );
            if (initAvailabilities?.startTime === hour.startTime) {
              return {
                ...hour,
                selected: !hour.selected,
              };
            }

            return hour;
          }),
        };
      }
    );
    setAvailabilities(newAvailabilities);
  }, []);

  const selectHourHandler = (day: string, index: number) => {
    if (!disabled) {
      let newWithSingleAvailabilities =
        disabled !== null
          ? availabilities
          : availabilities.map((day) => {
              return {
                ...day,
                hours: day.hours.map((hour: Hour) => {
                  if (hour.selected === true) {
                    return {
                      ...hour,
                      selected: false,
                    };
                  }

                  return hour;
                }),
              };
            });

      const newAvailabilities: AvailabilitiesProps =
        newWithSingleAvailabilities.map((week, weekIndex) => {
          if (week.day === day) {
            return {
              ...week,
              hours: week.hours.map((hour: Hour, hourIndex) => {
                if (hourIndex === index) {
                  addSelectedHourHandler(
                    weekIndex,
                    hour.startTime,
                    hour.endTime
                  );

                  if (hour.selected === true)
                    removeSelectedHourHandler(
                      weekIndex,
                      hour.startTime,
                      hour.endTime
                    );

                  return {
                    ...hour,
                    selected: !hour.selected,
                  };
                }

                return hour;
              }),
            };
          }

          return week;
        });

      setAvailabilities(newAvailabilities);
    }
  };

  const addSelectedHourHandler = (
    dayOfTheWeek: number,
    startTime: string,
    endTime: string
  ) => {
    let availabilities = [
      ...contextState.availabilitySelected,
      { dayOfTheWeek, startTime, endTime },
    ];

    // if(disabled === null) availabilities = [availabilities[availabilities.length - 1]];

    setContextState({
      ...contextState,
      availabilitySelected: availabilities,
    });

    onSelect(availabilities);
  };
  const removeSelectedHourHandler = (
    dayOfTheWeek: number,
    startTime: string,
    endTime: string
  ) => {
    let filteredAvailabilitySelected = contextState.availabilitySelected.filter(
      (availability: any) => {
        return (
          availability.dayOfTheWeek !== dayOfTheWeek ||
          (availability.startTime !== startTime &&
            availability.endTime !== endTime)
        );
      }
    );

    setContextState({
      ...contextState,
      availabilitySelected: [...filteredAvailabilitySelected],
    });

    onSelect(filteredAvailabilitySelected);
  };

  return (
    <li
      className={`${hour.selected ? "active" : null} ${
        disabled ? "disabled" : null
      }`}
      onClick={() => selectHourHandler(day, index)}
      style={
        disabled === null ? { padding: "6px 4.5px", fontSize: "11px" } : {}
      }
    >
      {disabled === null && `${hour.startTime}-${hour.endTime}`}
      {disabled !== null && `${hour.startTime}h - ${hour.endTime}h`}
    </li>
  );
};

export default AvailabilityTime;
