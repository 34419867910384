export default function TutoringIcon(props: any) {
  return (
    <svg
      {...props}
      width="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 3.02191C6.4897 2.37519 5.28831 2 4 2C3.24119 2 2.51171 2.13023 1.83338 2.36999C1.63358 2.44061 1.5 2.6295 1.5 2.84141V12.3414C1.5 12.5037 1.57879 12.6559 1.71131 12.7497C1.84383 12.8433 2.01359 12.8669 2.16662 12.8128C2.73941 12.6104 3.35627 12.5 4 12.5C5.33001 12.5 6.54883 12.9715 7.5 13.7571V3.02191Z"
        fill="#FF6B43"
      />
      <path
        d="M8.5 13.7571C9.4512 12.9715 10.67 12.5 12 12.5C12.6437 12.5 13.2606 12.6104 13.8334 12.8128C13.9864 12.8669 14.1562 12.8433 14.2887 12.7497C14.4212 12.6559 14.5 12.5037 14.5 12.3414V2.84141C14.5 2.6295 14.3664 2.44061 14.1666 2.36999C13.4883 2.13023 12.7588 2 12 2C10.7117 2 9.51033 2.37519 8.5 3.02191V13.7571Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
