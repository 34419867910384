export default function AcademicHatIcon(props: any) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79987 1.87022C7.92747 1.81446 8.0726 1.81446 8.2002 1.87022C10.6795 2.95344 13.0291 4.27761 15.2197 5.81307C15.3782 5.92418 15.4583 6.11683 15.4254 6.3076C15.3925 6.49837 15.2523 6.65297 15.0657 6.7045C14.6091 6.8305 14.1565 6.9663 13.7079 7.1109C11.8871 7.6981 10.1345 8.43696 8.4648 9.31256L8.4626 9.3137C8.38687 9.35343 8.31133 9.39336 8.236 9.43363C8.08873 9.5123 7.91193 9.5123 7.76473 9.43363C7.68867 9.39303 7.6124 9.35263 7.53593 9.31256C6.71033 8.87956 5.86439 8.4801 5 8.11576V7.9667C5 7.8791 5.04495 7.80136 5.11449 7.7593C6.1179 7.15136 7.15293 6.59047 8.21647 6.07961C8.4654 5.96004 8.57027 5.66133 8.45073 5.41242C8.33113 5.1635 8.0324 5.05864 7.78353 5.1782C6.69067 5.70312 5.62724 6.27942 4.59633 6.90396C4.29407 7.0871 4.09088 7.38677 4.02405 7.72256C3.4541 7.5033 2.87666 7.2991 2.29219 7.11063C1.84364 6.96597 1.39096 6.8305 0.93436 6.7045C0.747747 6.65296 0.607641 6.49836 0.574693 6.30759C0.541746 6.11683 0.621883 5.92418 0.780407 5.81307C2.97097 4.27761 5.32065 2.95344 7.79987 1.87022Z"
        fill="#FF6B43"
      />
      <path
        d="M8.70729 10.3156C10.3332 9.44689 12.0414 8.71249 13.8172 8.12709C13.9068 9.07029 13.9639 10.0229 13.9876 10.9837C13.9926 11.1898 13.8706 11.378 13.6804 11.4574C11.7796 12.251 9.97162 13.2227 8.27729 14.3518C8.10942 14.4637 7.89069 14.4637 7.72276 14.3518C6.02847 13.2227 4.22051 12.251 2.31976 11.4574C2.12946 11.378 2.00746 11.1898 2.01254 10.9837C2.03622 10.0228 2.09336 9.07016 2.18287 8.12689C2.79687 8.32923 3.40279 8.54949 4.00002 8.78689V9.63383C3.70112 9.80669 3.50002 10.1299 3.50002 10.5C3.50002 10.8302 3.66008 11.1231 3.90684 11.3052C3.84716 11.5589 3.75884 11.8072 3.64187 12.045C3.94366 12.1874 4.24288 12.3345 4.53942 12.486C4.70831 12.1425 4.83077 11.782 4.90678 11.4138C5.25635 11.258 5.50002 10.9075 5.50002 10.5C5.50002 10.1299 5.29892 9.80669 5.00002 9.63383V9.20396C5.78096 9.54516 6.54592 9.91623 7.29349 10.3156C7.73522 10.5516 8.26556 10.5516 8.70729 10.3156Z"
        fill="#FF6B43"
      />
      <path
        d="M2.97481 12.9749C3.25424 12.6955 3.47668 12.3808 3.64185 12.045C3.94364 12.1874 4.24285 12.3345 4.5394 12.486C4.32703 12.9179 4.04109 13.3228 3.68192 13.682C3.48666 13.8772 3.17008 13.8772 2.97481 13.682C2.77955 13.4868 2.77955 13.1702 2.97481 12.9749Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
