export default function SearchIcon(props: any) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4266_8894)">
        <path
          d="M12.0206 11.0787L14.8759 13.9333L13.9326 14.8767L11.0779 12.0213C10.0157 12.8728 8.69459 13.3359 7.33325 13.334C4.02125 13.334 1.33325 10.646 1.33325 7.33398C1.33325 4.02198 4.02125 1.33398 7.33325 1.33398C10.6453 1.33398 13.3333 4.02198 13.3333 7.33398C13.3352 8.69532 12.8721 10.0165 12.0206 11.0787ZM10.6833 10.584C11.5293 9.71391 12.0018 8.5476 11.9999 7.33398C11.9999 4.75532 9.91125 2.66732 7.33325 2.66732C4.75459 2.66732 2.66659 4.75532 2.66659 7.33398C2.66659 9.91198 4.75459 12.0007 7.33325 12.0007C8.54687 12.0026 9.71318 11.5301 10.5833 10.684L10.6833 10.584Z"
          fill="#B9BCC3"
        />
      </g>
      <defs>
        <clipPath id="clip0_4266_8894">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
