import { accessPageList } from "../userPrivilages/accessPageList";
import useLocalStorage from "./useLocalStorage";

export const useAccessControl = (pageName: string, action: string) => {
  const [user] = useLocalStorage("user", {});

  // Find the permissions for the given page name in the accessPageList
  const permissions = accessPageList[pageName] || {};
  const allowedRoles = permissions[action] || [];
  const hasAccess = allowedRoles.includes(user?.userRole || null);
  return hasAccess;
};
