import Sidebar from "./components/menu/ISSidebar";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Layout, Row, Col } from "antd";
import ISMenuMobile from "./components/header/ISMenuMobile";

const { Content } = Layout;

export default function InternalSystemLayout(props: any) {
  const { children } = props;
  const [visible, setVisible] = useState(false);
  //@ts-ignore
  const customise = useSelector((state) => state.customise);

  return (
    <Layout className="hp-app-layout">
      <Sidebar visible={visible} setVisible={setVisible} />
      <Layout
        style={{ background: "#F7F8FA" }}
        className="hp-position-relative"
      >
        <ISMenuMobile setVisible={setVisible} />

        <Content className="hp-content-main hp-py-35">
          <Row style={{ background: "none" }} justify="center">
            {customise.contentWidth === "full" && (
              <Col xxl={24} xl={24} span={24}>
                {children}
              </Col>
            )}

            {customise.contentWidth === "boxed" && (
              <Col className="hp-w-100" style={{ maxWidth: 936 }}>
                {children}
              </Col>
            )}
          </Row>
        </Content>
        {/* <MenuFooter /> */}
      </Layout>
    </Layout>
  );
}
