export default function UsersIcon(props: any) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4.25C3 2.73122 4.23122 1.5 5.75 1.5C7.2688 1.5 8.5 2.73122 8.5 4.25C8.5 5.76878 7.2688 7 5.75 7C4.23122 7 3 5.76878 3 4.25Z"
        fill="#FF6B43"
      />
      <path
        d="M9.5 5.75C9.5 4.50736 10.5073 3.5 11.75 3.5C12.9927 3.5 14 4.50736 14 5.75C14 6.99267 12.9927 8 11.75 8C10.5073 8 9.5 6.99267 9.5 5.75Z"
        fill="#FF6B43"
      />
      <path
        d="M1 12.75C1 10.1267 3.12665 8 5.75 8C8.37333 8 10.5 10.1267 10.5 12.75V12.7517C10.5 12.7783 10.4997 12.8049 10.4993 12.8313C10.4964 13.0036 10.4049 13.1623 10.2573 13.2512C8.94047 14.0441 7.39773 14.5 5.75 14.5C4.10229 14.5 2.55953 14.0441 1.24271 13.2512C1.09504 13.1623 1.00358 13.0036 1.00069 12.8313C1.00023 12.8043 1 12.7771 1 12.75Z"
        fill="#FF6B43"
      />
      <path
        d="M11.4999 12.7521C11.4999 12.7841 11.4996 12.8163 11.4991 12.8481C11.4953 13.0739 11.4408 13.2919 11.3438 13.488C11.4781 13.4959 11.6135 13.5 11.7499 13.5C12.8137 13.5 13.8213 13.2535 14.7176 12.8142C14.8823 12.7335 14.9895 12.5691 14.9971 12.3858C14.9989 12.3407 14.9999 12.2955 14.9999 12.25C14.9999 10.4551 13.5448 9 11.7499 9C11.2523 9 10.7807 9.11187 10.3591 9.31173C11.0757 10.2707 11.4999 11.4608 11.4999 12.75V12.7521Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
