import { Button } from "antd";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { EditIcon } from "../icons";
import { useAccessControl } from "../../hooks/useAccessControl";
import { PermissionActions } from "../../utils/const";

type Props = {
  page: string;
  path: string;
};

const EditBtn: FC<Props> = ({ page, path }) => {
  const hasUpdateAccess = useAccessControl(page, PermissionActions.UPDATE);

  return (
    <>
      {hasUpdateAccess && (
        <Link to={path} className="hp-text-color-black-100">
          <Button
            icon={
              <EditIcon className="mr-8 remix-icon hp-text-color-primary-1" />
            }
            size="small"
            className={`btn-gray`}
          >
            <FormattedMessage id="edit" />
          </Button>
        </Link>
      )}
    </>
  );
};

export default EditBtn;
