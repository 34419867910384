import { Button, Col, Row } from "antd";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { AddIcon } from "../icons";
import { useAccessControl } from "../../hooks/useAccessControl";
import { ISSetupPaths, PermissionActions } from "../../utils/const";

type PageTableHeaderProps = {
  page: string;
  type: string;
  path: any;
  hideMultiple?: boolean;
};

export default function PageTableHeader(props: PageTableHeaderProps) {
  const intl = useIntl();
  const { page, type, path = true, hideMultiple = false } = props;

  const hasCreateAccess = useAccessControl(page, PermissionActions.CREATE);

  const addPlaceholder = intl.formatMessage({ id: "add" });
  const textPlaceholder = intl.formatMessage({ id: type });
  const placeholder = `${addPlaceholder} ${textPlaceholder}`;
  return (
    <Row className="hp-mb-60" align="middle" justify="space-between">
      <Col>
        <h2 className="hp-text-color-primary-1 hp-mb-0">{textPlaceholder}</h2>
      </Col>

      {path !== false &&
        path !== `${ISSetupPaths.SECTORS}/add` &&
        hasCreateAccess && (
          <Col>
            <Link to={path}>
              <Button
                type="primary"
                className="hp-mr-sm-8 hp-mr-16"
                icon={<AddIcon className="remix-icon" />}
              >
                {placeholder}
              </Button>
            </Link>
          </Col>
        )}
    </Row>
  );
}
