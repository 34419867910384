export default function HomeIcon(props: any) {
  return (
    <svg
      {...props}
      width="18"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4306 12.7429C11.434 12.7429 12.2506 13.5537 12.2506 14.5504V17.1137C12.2506 17.3279 12.4223 17.4996 12.6423 17.5046H14.2306C15.4823 17.5046 16.4998 16.4996 16.4998 15.2646V7.99454C16.494 7.56954 16.2915 7.16954 15.944 6.9037L10.4498 2.52204C9.71231 1.93787 8.68064 1.93787 7.94064 2.5237L2.48394 6.90204C2.12311 7.1762 1.92061 7.5762 1.91644 8.0087V15.2646C1.91644 16.4996 2.93394 17.5046 4.18561 17.5046H5.78894C6.01478 17.5046 6.19811 17.3254 6.19811 17.1054C6.19811 17.0571 6.20394 17.0087 6.21394 16.9629V14.5504C6.21394 13.5596 7.02561 12.7496 8.02148 12.7429H10.4306ZM14.2306 18.7546H12.6273C11.709 18.7329 11.0006 18.0121 11.0006 17.1137V14.5504C11.0006 14.2429 10.7448 13.9929 10.4306 13.9929H8.02564C7.71814 13.9946 7.46398 14.2454 7.46398 14.5504V17.1054C7.46398 17.1679 7.45564 17.2279 7.43814 17.2846C7.34814 18.1096 6.64311 18.7546 5.78894 18.7546H4.18561C2.24478 18.7546 0.666443 17.1887 0.666443 15.2646V8.00287C0.674776 7.17454 1.05644 6.4162 1.71561 5.91704L7.16144 1.5462C8.36064 0.596205 10.0315 0.596205 11.2281 1.54454L16.7131 5.91954C17.3573 6.41037 17.739 7.16704 17.7498 7.98537V15.2646C17.7498 17.1887 16.1715 18.7546 14.2306 18.7546Z"
        fill="#FF6B43"
        stroke="#F0F1F5"
        strokeWidth="0.5"
      />
    </svg>
  );
}
