import { FormattedMessage } from "react-intl";

import {
  AcademicHatIcon,
  ManageIcon,
  UsersIcon,
  TutoringIcon,
} from "../../view/icons";

import {
  ISManagePaths,
  ISProfileRolesPaths,
  ISSetupPaths,
  ISTutoringPaths,
  UserRoles,
} from "../../utils/const";
import { filterMenusBasedOnProgram } from "../../helpers/helpers";

const VSCMenu = [
  {
    id: "manage",
    title: (
      <span style={{ fontWeight: "500" }}>
        <FormattedMessage id="manage" />
      </span>
    ),
    icon: <ManageIcon />,
    children: [
      {
        id: "tutors",
        title: <FormattedMessage id="tutors" />,
        navLink: ISManagePaths.TUTORS,
      },
      {
        id: "parents",
        title: <FormattedMessage id="parents" />,
        navLink: ISManagePaths.PARENTS,
      },
      {
        id: "students",
        title: <FormattedMessage id="students" />,
        navLink: ISManagePaths.STUDENTS,
      },
      // The VSC doesn't have access to the matching page
      // {
      //   id: "matching",
      //   title: <FormattedMessage id="matching" />,
      //   navLink: ISManagePaths.MATCHING,
      // },
      {
        id: "establishments",
        title: <FormattedMessage id="establishments" />,
        navLink: ISManagePaths.ESTABLISHMENTS,
      },
    ],
  },
  {
    id: "tutoring",
    title: (
      <span style={{ fontWeight: "500" }}>
        <FormattedMessage id="tutoring" />
      </span>
    ),
    icon: <TutoringIcon />,
    children: [
      {
        id: "sessions",
        title: <FormattedMessage id="sessions" />,
        navLink: ISTutoringPaths.SESSIONS,
      },
      {
        id: "cr-reports",
        title: <FormattedMessage id="cr-reports" />,
        navLink: ISTutoringPaths.CR_REPORTS,
      },
      {
        id: "sc-reports",
        title: <FormattedMessage id="sc-reports" />,
        navLink: ISTutoringPaths.SC_REPORTS,
      },
    ],
  },
  // {
  //   id: "setup",
  //   title: <span style={{fontWeight: '500'}}>
  //     <FormattedMessage id="setup" />
  //   </span>,
  //   icon: <AcademicHatIcon />,
  //   children: [
  //     {
  //       id: "scholar-year",
  //       title: <FormattedMessage id="scholar-year" />,
  //       navLink: ISSetupPaths.SCHOLAR_YEAR,
  //     },
  //     {
  //       id: "school-zones",
  //       title: <FormattedMessage id="school-zones" />,
  //       navLink: ISSetupPaths.SCHOOL_ZONES,
  //     },
  //     {
  //       id: "public-holidays",
  //       title: <FormattedMessage id="public-holidays" />,
  //       navLink: ISSetupPaths.PUBLIC_HOLIDAYS,
  //     },
  //     {
  //       id: "availability",
  //       title: <FormattedMessage id="availability" />,
  //       navLink: ISSetupPaths.AVAILABILITY,
  //     },
  //     {
  //       id: "sectors",
  //       title: <FormattedMessage id="sectors" />,
  //       navLink: ISSetupPaths.SECTORS,
  //     },
  //   ],
  // },
  // {
  //   id: "profile",
  //   title: <span style={{fontWeight: '500'}}>
  //     <FormattedMessage id="profile-and-roles" />
  //   </span>,
  //   icon: <UsersIcon />,
  //   children: [
  //     {
  //       id: "administrators",
  //       title: <FormattedMessage id="administrators" />,
  //       navLink: ISProfileRolesPaths.ADMINISTRATORS,
  //     },
  //     {
  //       id: "managers",
  //       title: <FormattedMessage id="managers" />,
  //       navLink: ISProfileRolesPaths.MANAGERS,
  //     },
  //     {
  //       id: "coordinators",
  //       title: <FormattedMessage id="coordinators" />,
  //       navLink: ISProfileRolesPaths.COORDINATORS,
  //     },
  //     {
  //       id: "vsc",
  //       title: <FormattedMessage id="vsc" />,
  //       navLink: ISManagePaths.VSC,
  //     },
  //   ],
  // },
];

const filteredVSCMenu = filterMenusBasedOnProgram(VSCMenu);

export default filteredVSCMenu;
