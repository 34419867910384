export default function ReferentIcon(props: any) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 4C10.5 5.38071 9.38072 6.5 7.99999 6.5C6.61929 6.5 5.5 5.38071 5.5 4C5.5 2.61929 6.61929 1.5 7.99999 1.5C9.38072 1.5 10.5 2.61929 10.5 4Z"
        stroke="#FF6B43"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.78537 2.25C9.33164 2.71284 8.69937 3 8.00001 3C7.30067 3 6.66839 2.71284 6.21466 2.25C6.66839 1.78716 7.30067 1.5 8.00001 1.5C8.69937 1.5 9.33164 1.78716 9.78537 2.25Z"
        fill="#FF6B43"
      />
      <path
        d="M9.78537 2.25L10.3209 2.77503C10.6068 2.48339 10.6068 2.01661 10.3209 1.72497L9.78537 2.25ZM6.21466 2.25L5.67909 1.72497C5.39319 2.01661 5.39319 2.48339 5.67909 2.77503L6.21466 2.25ZM9.2498 1.72497C8.93132 2.04984 8.48972 2.25 8.00001 2.25V3.75C8.90901 3.75 9.73197 3.37584 10.3209 2.77503L9.2498 1.72497ZM8.00001 2.25C7.51032 2.25 7.06872 2.04985 6.75024 1.72497L5.67909 2.77503C6.26806 3.37583 7.09102 3.75 8.00001 3.75V2.25ZM6.75024 2.77503C7.06872 2.45015 7.51032 2.25 8.00001 2.25V0.75C7.09102 0.75 6.26806 1.12417 5.67909 1.72497L6.75024 2.77503ZM8.00001 2.25C8.48972 2.25 8.93132 2.45016 9.2498 2.77503L10.3209 1.72497C9.73197 1.12416 8.90901 0.75 8.00001 0.75V2.25Z"
        fill="#FF6B43"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8C4.99463 8 2.55237 10.4104 2.50081 13.4035C2.49739 13.6021 2.61175 13.7838 2.79219 13.8666C4.37827 14.5945 6.14255 15 8.0002 15C9.85767 15 11.6218 14.5945 13.2077 13.8668C13.3882 13.784 13.5025 13.6023 13.4991 13.4037C13.4477 10.4105 11.0054 8 8 8ZM8.66667 11.5C8.39052 11.5 8.16667 11.7239 8.16667 12C8.16667 12.2761 8.39052 12.5 8.66667 12.5H10.6667C10.9428 12.5 11.1667 12.2761 11.1667 12C11.1667 11.7239 10.9428 11.5 10.6667 11.5H8.66667Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
