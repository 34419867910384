import { FormattedMessage } from "react-intl";

import {
  AccountIcon,
  CalendarIcon,
  AcademicHatIcon,
  ChipIcon,
  HomeIcon,
  QuestionMarkIcon,
} from "../../view/icons";

const studentMenu = [
  {
    id: "student-accueil",
    title: <FormattedMessage id="homepage" />,
    icon: <HomeIcon />,
    navLink: "/",
  },
  {
    id: "student-séances",
    title: <FormattedMessage id="my-sessions" />,
    icon: <CalendarIcon />,
    navLink: "/student/sessions",
  },
  {
    id: "student-séances",
    title: <FormattedMessage id="my-sessions-list" />,
    icon: <AcademicHatIcon />,
    navLink: "/student/listSessions",
  },
  {
    id: "student-compte",
    title: <FormattedMessage id="my-account" />,
    icon: <AccountIcon />,
    navLink: "/student/account",
  },
  {
    id: "student-equipment",
    title: <FormattedMessage id="equipments-tester" />,
    icon: <ChipIcon />,
    navLink: "/student/equipment",
  },
  {
    id: "student-contacter",
    title: <FormattedMessage id="contact-zupdeco" />,
    icon: <QuestionMarkIcon />,
    navLink: "/student/contact-zupdeco",
  },
];

export default studentMenu;
