import Line from "../errors/line";
import MenuLogo from "../../layout/components/menu/logo";

import { Helmet } from "react-helmet";
import { Col, Row } from "antd";

export default function Terms() {
  return (
    <Row className="hp-text-center hp-overflow-hidden">
      <Line />

      <Helmet>
        <title>Terms of Service</title>
      </Helmet>

      <Col
        span={24}
        className="hp-d-flex-full-center hp-border-bottom-1 hp-border-color-dark-60 hp-py-32"
      >
        <MenuLogo />
      </Col>

      <Col className="hp-error-content hp-py-32" span={24}>
        <Row className="hp-h-100 text-align-start p-25p hp-mt-40">
          <Col>
            <h2 className="h1 hp-mb-16">Terms of Service</h2>

            <p className="hp-mb-32 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
              Voluptate, atque corporis! Accusantium ullam, impedit molestiae
              quisquam sapiente exercitationem ea suscipit, harum, saepe
              praesentium deserunt tempora iusto?
              <br />
              <br />
              Quo dolorum obcaecati libero architecto perferendis tempora
              similique, magnam perspiciatis neque non amet voluptates ab harum
              pariatur rerum consequatur saepe quibusdam nihil. Laboriosam
              debitis eos, at distinctio error animi maiores consequatur.
              <br />
              <br />
              Sapiente tenetur earum aliquam laborum, aut a ea voluptatibus
              magnam fuga minima omnis maiores nesciunt aliquid placeat
              voluptate molestias nihil enim saepe necessitatibus et laboriosam?
              Fugiat ut maiores deleniti voluptatum, consectetur nesciunt omnis
              dolor reiciendis culpa corrupti illum voluptate aspernatur veniam
              officia praesentium!
              <br />
              <br />
              Inventore fugit reiciendis dolores deleniti suscipit alias iure
              enim repudiandae officia voluptate, est sint quia voluptates
              tempore consequatur? Explicabo eveniet dolorum exercitationem ut
              dolores, quod unde quaerat magnam dolorem expedita aperiam
              temporibus quasi debitis illo ullam quis.
              <br />
              <br />
              Nihil unde error excepturi. Recusandae, beatae non! Atque iure
              quibusdam quae, debitis laboriosam culpa deleniti error quisquam
              quaerat ex asperiores sed eos exercitationem! Atque nam iure
              mollitia, vitae ducimus esse! Aliquam voluptate pariatur hic
              tempora quos, et neque saepe debitis a voluptatum dolore?
              <br />
              <br />
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
              voluptatum unde dolores autem ut nesciunt provident voluptate quam
              culpa, officia recusandae nostrum, nisi labore temporibus vitae
              ullam deleniti inventore. Ullam, quis esse.
              <br />
              <br />
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit eum
              animi debitis repellendus deleniti hic vero iusto quia, voluptate
              fugit similique. Accusamus ea rem ab dolore cupiditate excepturi,
              magnam laboriosam harum beatae aliquam quidem dignissimos incidunt
              at commodi autem, facilis inventore quasi aut. Odit doloremque
              cupiditate ea fugit aliquid. Eius, doloribus error.
              <br />
              <br />
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum
              sapiente tenetur maiores necessitatibus deleniti ad ducimus
              doloremque, vero eveniet alias facere assumenda ea praesentium
              modi natus perspiciatis saepe! Dolorem totam voluptatum earum
              alias nostrum aperiam sit ipsum ratione possimus officiis minima
              officia voluptatem corrupti voluptatibus excepturi vitae, cum
              quisquam iure atque facilis. Similique, nisi id optio iste eius
              expedita impedit omnis ut, aut, ipsam sint labore consequuntur in
              magnam sunt. Impedit, repudiandae.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
