import { lazy } from "react";
import { RedirectPaths, UserRoles } from "../../utils/const";

const StudentPages = [
  {
    path: "/",
    component: lazy(() => import("../../view/student/dashboard/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.STUDENT_LOGIN,
  },
  {
    path: `/${UserRoles.STUDENT}/sessions`,
    component: lazy(() => import("../../view/student/sessions/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.STUDENT_LOGIN,
  },
  {
    path: `/${UserRoles.STUDENT}/listSessions`,
    component: lazy(() => import("../../view/student/sessions/listSessions")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.STUDENT_LOGIN,
  },
  {
    path: `/${UserRoles.STUDENT}/account`,
    component: lazy(() => import("../../view/student/account")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.STUDENT_LOGIN,
  },
  {
    path: `/${UserRoles.STUDENT}/equipment`,
    component: lazy(() => import("../../view/student/equipment/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.STUDENT_LOGIN,
  },
  {
    path: `/${UserRoles.STUDENT}/contact-zupdeco`,
    component: lazy(() => import("../../view/student/contact/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.STUDENT_LOGIN,
  },
];

export default StudentPages;
