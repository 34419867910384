import { FormattedMessage } from "react-intl";
import { Translation } from "../../utils";
import { Form, InputNumber } from "antd";

export default function NumberInput(props: any) {
  const { labelName, name, rules, loading, className, min, max } = props;

  return (
    <div className={className || "row"}>
      <Form.Item
        label={<FormattedMessage id={labelName} />}
        name={name}
        rules={rules}
        className="hp-mb-0"
      >
        <InputNumber
          className="hp-w-100"
          placeholder={`${Translation("enter")} ${Translation(labelName)}`}
          min={min}
          type="number"
          size="large"
          max={max}
          disabled={loading}
        />
      </Form.Item>
    </div>
  );
}
