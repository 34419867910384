import { lazy } from "react";

import {
  ISManagePaths,
  ISTutoringPaths,
  RedirectPaths,
} from "../../utils/const";

// MANAGE
const tutors = [
  {
    path: `${ISManagePaths.TUTORS}/view/:tutorId`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/tutors/view_tutor")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.TUTORS}`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/tutors/list_tutors")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.TUTORS}/edit/:tutorId`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/tutors/edit_tutor")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.TUTORS}/add`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/tutors/add_tutor")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const parents = [
  {
    path: `${ISManagePaths.PARENTS}`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/parents/list_parents")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.PARENTS}/view/:parentId`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/parents/view_parent")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.PARENTS}/edit/:parentId`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/parents/edit_parent")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.PARENTS}/add`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/parents/add_parent")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const students = [
  {
    path: `${ISManagePaths.STUDENTS}`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/students/list_students")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.STUDENTS}/add`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/students/add_student")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.STUDENTS}/view/:studentId`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/students/view_student")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISManagePaths.STUDENTS}/edit/:studentId`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/students/edit_student")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const matching = [
  {
    path: `${ISManagePaths.MATCHING}`,
    component: lazy(
      () => import("../../view/InternalSystem/manage/matching/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

// TUTORING
const sessions = [
  {
    path: `${ISTutoringPaths.SESSIONS}`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/tutoring/sessions/list_sessions/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISTutoringPaths.SESSIONS}/add`,
    component: lazy(
      () =>
        import("../../view/InternalSystem/tutoring/sessions/add_session/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISTutoringPaths.SESSIONS}/view/:sessionId`,
    component: lazy(
      () =>
        import("../../view/InternalSystem/tutoring/sessions/view_session/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISTutoringPaths.SESSIONS}/edit/:sessionId`,
    component: lazy(
      () =>
        import("../../view/InternalSystem/tutoring/sessions/edit_session/index")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const cr_reports = [
  {
    path: `${ISTutoringPaths.CR_REPORTS}`,
    component: lazy(
      () => import("../../view/InternalSystem/tutoring/cr-reports/list_cr")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISTutoringPaths.CR_REPORTS}/view/:crId`,
    component: lazy(
      () => import("../../view/InternalSystem/tutoring/cr-reports/view_cr")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISTutoringPaths.CR_REPORTS}/edit/:crId`,
    component: lazy(
      () => import("../../view/InternalSystem/tutoring/cr-reports/edit_cr")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];
const establishments = [
  {
    path: `${ISManagePaths.ESTABLISHMENTS}`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/manage/establishment/list_establishment/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },

  {
    path: `${ISManagePaths.ESTABLISHMENTS}/view/:establishmentId`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/manage/establishment/view_establishment/index"
        )
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];
const sc_reports = [
  {
    path: `${ISTutoringPaths.SC_REPORTS}`,
    component: lazy(
      () => import("../../view/InternalSystem/tutoring/sc-reports/list_sc")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISTutoringPaths.SC_REPORTS}/view/:scId`,
    component: lazy(
      () => import("../../view/InternalSystem/tutoring/sc-reports/view_sc")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
  {
    path: `${ISTutoringPaths.SC_REPORTS}/edit/:scId`,
    component: lazy(
      () => import("../../view/InternalSystem/tutoring/sc-reports/edit_sc")
    ),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

const VSCPages = [
  {
    path: `/`,
    component: lazy(() => import("../../view/InternalSystem/index")),
    layout: "InternalSystemLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },

  // MANAGE
  ...tutors,
  ...parents,
  ...students,
  ...matching,
  ...establishments,

  // TUTORING
  ...sessions,
  ...cr_reports,
  ...sc_reports,
];

export default VSCPages;
