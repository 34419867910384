import { FormattedMessage } from "react-intl";
import { Translation } from "./";

export const showPlaceholders = true;

export const Required = (target: string, intl?: any) => {
  let placeholder = "";
  let name = "";

  if (intl) {
    placeholder = intl.formatMessage({ id: "is-required" });
    name = intl.formatMessage({ id: target });
  } else {
    placeholder = Translation("is-required");
    name = Translation(target);
  }

  if (showPlaceholders) return <>{`${name} ${placeholder}`}</>;
  else return <FormattedMessage id="is-required-full" />;
};

export const Invalid = (target: string, intl?: any) => {
  let placeholder = "";
  let name = "";

  if (intl) {
    placeholder = intl.formatMessage({ id: "is-invalid" });
    name = intl.formatMessage({ id: target });
  } else {
    placeholder = Translation("is-invalid");
    name = Translation(target);
  }

  if (showPlaceholders) return <>{`${name} ${placeholder}`}</>;
  else return <FormattedMessage id="is-invalid-full" />;
};

export const Whitespace = (target: string, intl?: any) => {
  let placeholder = "";
  let name = "";

  if (intl) {
    placeholder = intl.formatMessage({ id: "allows-not-whitspaces" });
    name = intl.formatMessage({ id: target });
  } else {
    placeholder = Translation("allows-not-whitspaces");
    name = Translation(target);
  }

  if (showPlaceholders) return <>{`${name} ${placeholder}`}</>;
  else return <FormattedMessage id="allows-not-whitspaces-full" />;
};

export const Min = (target: string, min: number, intl?: any) => {
  let placeholder = "";
  let nameHalf = "";
  let nameFull = "";

  if (intl) {
    placeholder = intl.formatMessage({ id: "is-invalid" });
    nameHalf = intl.formatMessage({ id: target });
    nameFull = intl.formatMessage({ id: "is-min-full" });
  } else {
    placeholder = Translation("is-min");
    nameFull = Translation("is-min-full");
    nameHalf = Translation(target);
  }

  if (showPlaceholders) return <>{`${nameHalf} ${placeholder}`}</>;
  else return <>{`${min} ${nameFull}`}</>;
};

export const Max = (target: string, min: number, intl?: any) => {
  let placeholder = "";
  let nameHalf = "";
  let nameFull = "";

  if (intl) {
    placeholder = intl.formatMessage({ id: "is-invalid" });
    nameHalf = intl.formatMessage({ id: target });
    nameFull = intl.formatMessage({ id: "is-min-full" });
  } else {
    placeholder = Translation("is-max-full");
    nameFull = Translation("is-min-full");
    nameHalf = Translation(target);
  }

  if (showPlaceholders)
    return (
      <>{`${nameHalf} ${placeholder} ${min} ${Translation("characters")}`}</>
    );
  else return <>{`${min} ${nameFull}`}</>;
};
