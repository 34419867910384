import useLocalStorage from "../../../../hooks/useLocalStorage";
import avatar from "../../../../assets/images/memoji/user-avatar-8.png";
import HeaderLanguages from "../../header/HeaderLanguages";

import { Link, useHistory } from "react-router-dom";
import { RiMore2Line } from "react-icons/ri";
import { Divider, Avatar, Row, Col, Dropdown, Menu, Button } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { useAuthContext } from "../../../../context/AuthContext";
import { UserRolesList } from "../../../../data";
import { EMPTY_VALUE, EMPTY_VALUE_SHORT } from "../../../../utils/const";

export default function MenuFooter(props: any) {
  const intl = useIntl();
  const history = useHistory();

  const [user] = useLocalStorage("user", {});
  const { logoutUser } = useAuthContext();

  function getPopupContainer(node: any) {
    return node.parentNode;
  }

  const rateMenu = (
    <Menu>
      <Menu.Item key="0">
        <HeaderLanguages mode="IS" />
      </Menu.Item>

      <Menu.Item key="1" onClick={() => logoutUser(user, history)}>
        <FormattedMessage id="logout" />
      </Menu.Item>
    </Menu>
  );

  if (props.collapsed)
    return (
      <Row
        className="hp-sidebar-footer hp-bg-color-dark-90"
        align="middle"
        justify="center"
      >
        <Col>
          <Link
            to="/pages/profile/personel-information"
            onClick={props.onClose}
          >
            <Avatar size={48} src={avatar} className="hp-bg-info-4" />
          </Link>
        </Col>
      </Row>
    );
  else
    return (
      <Row
        className="hp-sidebar-footer hp-bg-color-dark-90"
        align="middle"
        justify="space-between"
      >
        <Divider className="hp-border-color-black-40 hp-border-color-dark-70 hp-mt-0" />

        <Col>
          <Row align="middle">
            {user?.profilePic && (
              <Avatar
                size={32}
                src={user?.profilePic}
                className="hp-bg-info-4 hp-mr-12"
              />
            )}

            {!user?.profilePic && (
              <Avatar
                className="hp-bg-primary-1 hp-text-color-black-0 hp-mr-12"
                size={32}
              >
                {`${user?.contactDetails?.firstName ? user?.contactDetails?.firstName?.charAt(0) : EMPTY_VALUE_SHORT}${user?.contactDetails?.lastName ? user?.contactDetails?.lastName?.charAt(0) : EMPTY_VALUE_SHORT}`}
              </Avatar>
            )}

            <div className="hp-mt-6">
              <span
                className="hp-d-block hp-text-color-black-100 hp-text-color-dark-0 hp-p1-body"
                style={{ lineHeight: 1 }}
              >
                {`${user?.contactDetails?.firstName || EMPTY_VALUE} ${user?.contactDetails?.lastName || EMPTY_VALUE}`}
              </span>

              <span className="hp-badge-text hp-text-color-black-60 hp-font-weight-500">
                {UserRolesList(intl)?.map(
                  (role) => role.value === user?.userRole && role.name,
                )}
              </span>
            </div>
          </Row>
        </Col>

        <Col>
          <Dropdown
            overlay={rateMenu}
            placement="topLeft"
            getPopupContainer={getPopupContainer}
          >
            <Button
              type="text"
              icon={
                <RiMore2Line
                  className="hp-text-color-black-100 hp-text-color-dark-0"
                  size={24}
                />
              }
            />
          </Dropdown>
        </Col>
      </Row>
    );
}
