import ScrollToTop from "react-scroll-up";

import { Button } from "antd";
import { RiArrowUpLine } from "react-icons/ri";

export default function ScrollTop() {
  return (
    <div className="scroll-to-top">
      {/* @ts-ignore */}
      <ScrollToTop showUnder={300} style={{ bottom: "6%" }}>
        <Button type="primary" shape="circle" icon={<RiArrowUpLine />} />
      </ScrollToTop>
    </div>
  );
}
