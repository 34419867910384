import { Button } from "antd";
import { FormattedMessage } from "react-intl";

export default function PageAddButtons(props: any) {
  const { loading, onCancel, onConfirm, confirmId, size = "large" } = props;

  return (
    <div className="hp-d-flex hp-justify-content-end">
      <Button
        size={size}
        type="primary"
        onClick={onCancel}
        disabled={loading}
        className="hp-bg-black-0 hp-border-color-black-40 hp-text-color-black-100 hp-px-20 hp-mr-12"
      >
        <FormattedMessage id="cancel" />
      </Button>

      <Button
        size={size}
        disabled={loading}
        type="primary"
        className="hp-bg-primary-1 hp-border-color-primary-1 hp-px-20"
        onClick={onConfirm}
        loading={loading}
      >
        <FormattedMessage id={confirmId} />
      </Button>
    </div>
  );
}
