import { Badge } from "antd";
import { INVALID_TRANSLATION, ReportStatusColors } from "../../utils/const";
import { Translation } from "../../utils";

export default function ReportStatusBadge(status: any) {
  if (!status) return INVALID_TRANSLATION;
  let statusTranslation = Translation(status);
  let statusColor = ReportStatusColors[status] || "default";

  return <Badge status={statusColor} text={statusTranslation} />;
}
