export default function AccountIcon(props: any) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 7.5H15.625M12.5 10H15.625M12.5 12.5H15.625M3.75 16.25H16.25C17.2855 16.25 18.125 15.4105 18.125 14.375V5.625C18.125 4.58947 17.2855 3.75 16.25 3.75H3.75C2.71447 3.75 1.875 4.58947 1.875 5.625V14.375C1.875 15.4105 2.71447 16.25 3.75 16.25ZM8.75 7.8125C8.75 8.67542 8.05044 9.375 7.1875 9.375C6.32456 9.375 5.625 8.67542 5.625 7.8125C5.625 6.94956 6.32456 6.25 7.1875 6.25C8.05044 6.25 8.75 6.94956 8.75 7.8125ZM9.82825 13.0928C9.04075 13.5122 8.14184 13.75 7.18737 13.75C6.2329 13.75 5.33395 13.5122 4.54651 13.0928C4.94155 12.0172 5.97486 11.25 7.18737 11.25C8.39992 11.25 9.43317 12.0172 9.82825 13.0928Z"
        stroke="#323136"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
