import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { switchLanguage } from "../../../redux/customise/customiseActions";
import { Menu, Avatar, Card, Col } from "antd";

export default function HeaderLanguages({ mode = "header" }) {
  const dispatch = useDispatch();

  //@ts-ignore
  const customise = useSelector((state) => state.customise);

  const [activeLanguage, setActiveLanguage] = useState(customise.language);
  const languages = ["en", "fr"];

  useEffect(() => {
    setActiveLanguage(customise.language);
    localStorage.setItem("language", customise.language);
  }, [customise]);

  if (mode === "header") {
    return (
      <Col className="hp-languages hp-mr-8">
        <div className="hp-d-flex-center">
          <Avatar
            size={24}
            src={
              require(`../../../assets/images/languages/${activeLanguage}.svg`)
                .default
            }
          />
          <span
            className="hp-languages-text hp-text-color-black-80 hp-text-color-dark-30 hp-text-uppercase hp-ml-8"
            style={{ cursor: "pointer" }}
          >
            {activeLanguage}
          </span>
        </div>

        <div className="hp-languages-list">
          <Card className="hp-border-color-black-40 hp-border-radius">
            <Menu>
              {languages.map(
                (item: any, index: number) =>
                  item !== activeLanguage && (
                    <Menu.Item
                      key={index}
                      onClick={() => dispatch(switchLanguage(item))}
                    >
                      <div className="hp-d-flex-center">
                        <Avatar
                          size={24}
                          src={
                            require(
                              `../../../assets/images/languages/${item}.svg`,
                            ).default
                          }
                        />
                        <span className="hp-languages-text hp-text-color-black-80 hp-text-color-dark-30 hp-text-uppercase hp-ml-4">
                          {item}
                        </span>
                      </div>
                    </Menu.Item>
                  ),
              )}
            </Menu>
          </Card>
        </div>
      </Col>
    );
  } else
    return (
      <Col
        onClick={() =>
          dispatch(switchLanguage(activeLanguage === "en" ? "fr" : "en"))
        }
        className="hp-languages hp-mr-8"
      >
        <div className="hp-d-flex-center">
          <Avatar
            size={24}
            src={
              require(`../../../assets/images/languages/${activeLanguage}.svg`)
                .default
            }
          />
          <span className="hp-languages-text hp-text-color-black-80 hp-text-color-dark-30 hp-text-uppercase hp-ml-8">
            {activeLanguage === "en" ? "English" : "Français"}
          </span>
        </div>
      </Col>
    );
}
