export default function AddIcon(props: any) {
  return (
    <svg
      {...props}
      className={props.className || "remix-icon"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00004 14.6667C4.31804 14.6667 1.33337 11.682 1.33337 8C1.33337 4.318 4.31804 1.33333 8.00004 1.33333C11.682 1.33333 14.6667 4.318 14.6667 8C14.6667 11.682 11.682 14.6667 8.00004 14.6667ZM7.33337 7.33333H4.66671V8.66666H7.33337V11.3333H8.66671V8.66666H11.3334V7.33333H8.66671V4.66666H7.33337V7.33333Z"
        fill="white"
      />
    </svg>
  );
}
