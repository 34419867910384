export default function GoogleIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="remix-icon"
    >
      <path
        d="M4.98918 11.8776L4.36263 14.2166L2.07258 14.2651C1.3882 12.9957 1 11.5433 1 9.99998C1 8.50756 1.36295 7.10018 2.00631 5.86096H2.0068L4.04559 6.23474L4.9387 8.26129C4.75177 8.80625 4.64989 9.39125 4.64989 9.99998C4.64996 10.6606 4.76963 11.2936 4.98918 11.8776Z"
        fill="#FBBB00"
      />
      <path
        d="M18.8427 8.31873C18.9461 8.86316 19 9.42541 19 10C19 10.6444 18.9322 11.2729 18.8032 11.8792C18.3651 13.9422 17.2203 15.7437 15.6344 17.0186L15.6339 17.0181L13.066 16.887L12.7025 14.6182C13.7548 14.0011 14.5772 13.0353 15.0104 11.8792H10.1978V8.31873H18.8427Z"
        fill="#518EF8"
      />
      <path
        d="M15.6339 17.0181L15.6344 17.0185C14.092 18.2583 12.1328 19 9.99999 19C6.57257 19 3.59269 17.0843 2.07257 14.2651L4.98917 11.8777C5.74921 13.9061 7.70597 15.3501 9.99999 15.3501C10.986 15.3501 11.9098 15.0835 12.7024 14.6182L15.6339 17.0181Z"
        fill="#28B446"
      />
      <path
        d="M15.7447 3.07197L12.8291 5.45894C12.0087 4.94615 11.0389 4.64992 10 4.64992C7.65406 4.64992 5.6607 6.16013 4.93874 8.26131L2.00681 5.86098H2.00632C3.50418 2.97307 6.52165 1 10 1C12.1837 1 14.186 1.77787 15.7447 3.07197Z"
        fill="#F14336"
      />
    </svg>
  );
}
