import { AuthPaths } from "../utils/const";

export const allowed_routes: string[] = [
  AuthPaths.TUTOR_REGISTER,
  AuthPaths.TUTOR_LOGIN,
  AuthPaths.TUTOR_RECOVERY_PASSWORD,
  AuthPaths.TUTOR_RESET_PASSWORD,
  AuthPaths.PARENT_REGISTER,
  AuthPaths.PARENT_LOGIN,
  AuthPaths.PARENT_RECOVERY_PASSWORD,
  AuthPaths.PARENT_RESET_PASSWORD,
  AuthPaths.STUDENT_REGISTER,
  AuthPaths.STUDENT_LOGIN,
  AuthPaths.STUDENT_RECOVERY_PASSWORD,
  AuthPaths.STUDENT_RESET_PASSWORD,
  AuthPaths.IS_USER_REGISTER,
  AuthPaths.IS_USER_LOGIN,
  AuthPaths.IS_USER_RECOVERY_PASSWORD,
  AuthPaths.IS_USER_RESET_PASSWORD,
  AuthPaths.HOME,
];
export const allowed_routes_end_slash: string[] = [
  `${AuthPaths.TUTOR_REGISTER}/`,
  `${AuthPaths.TUTOR_LOGIN}/`,
  `${AuthPaths.TUTOR_RECOVERY_PASSWORD}/`,
  `${AuthPaths.TUTOR_RESET_PASSWORD}/`,
  `${AuthPaths.PARENT_REGISTER}/`,
  `${AuthPaths.PARENT_LOGIN}/`,
  `${AuthPaths.PARENT_RECOVERY_PASSWORD}/`,
  `${AuthPaths.PARENT_RESET_PASSWORD}/`,
  `${AuthPaths.STUDENT_REGISTER}/`,
  `${AuthPaths.STUDENT_LOGIN}/`,
  `${AuthPaths.STUDENT_RECOVERY_PASSWORD}/`,
  `${AuthPaths.STUDENT_RESET_PASSWORD}/`,
  `${AuthPaths.IS_USER_REGISTER}/`,
  `${AuthPaths.IS_USER_LOGIN}/`,
  `${AuthPaths.IS_USER_RECOVERY_PASSWORD}/`,
  `${AuthPaths.IS_USER_RESET_PASSWORD}/`,
  AuthPaths.HOME,
];
