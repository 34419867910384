import { createContext, useContext, useState } from "react";
import { IAppState } from "../ts/interfaces";
import moment from "moment";
const AppContext = createContext({});
export const useAppContext = () => useContext<any>(AppContext);

const AppStateProvider = ({ children }: any) => {
  const startDate = moment().startOf("month").format("YYYY-MM-DDTHH:mm:ssZ");
  const endDate = moment(startDate)
    .endOf("month") // Get the end of the current month
    .format("YYYY-MM-DDTHH:mm:ssZ");
  const [state, setState] = useState<IAppState>({
    user: {},
    availabilitySelected: [],
    calendarEvents: [],
    startDate,
    endDate,
  });
  return (
    <AppContext.Provider
      value={{ contextState: state, setContextState: setState }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppStateProvider;
