import { Breadcrumb, Col, Row, Skeleton } from "antd";
import { Translation } from "../../utils";
import { EMPTY_VALUE } from "../../utils/const";
import { PageBreadcrumbType } from "../../ts/types";

export default function PageBreadcrumb(props: PageBreadcrumbType) {
  const {
    previousTranslationKey,
    currentTranslationKey,
    isKeyTranslated = true,
  } = props;

  return (
    <Row className="hp-mb-40" align="middle" justify="space-between">
      <Col>
        <Breadcrumb className="hp-large-breadcrumb">
          <Breadcrumb.Item>
            <span className="hp-text-color-black-60">
              {Translation(previousTranslationKey) || EMPTY_VALUE}
            </span>
          </Breadcrumb.Item>

          {currentTranslationKey === null ? (
            <Skeleton.Input active={true} />
          ) : null}

          {currentTranslationKey !== null && (
            <Breadcrumb.Item>
              <span className="hp-text-color-primary-1">
                {isKeyTranslated
                  ? Translation(currentTranslationKey) || EMPTY_VALUE
                  : currentTranslationKey}
              </span>
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </Col>
    </Row>
  );
}
