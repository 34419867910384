export default function onState(
  target: string,
  value: any,
  values: any,
  setValues: any,
) {
  setValues({
    ...values,
    [target]: value,
  });
}
