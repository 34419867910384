import { Tooltip } from "antd";
import { Translation } from "../../utils";
import { AddReport, EditIcon, EyeIcon } from "../icons";
import { INVALID_TRANSLATION } from "../../utils/const";

export default function ActionsReports(props: any) {
  const { onView, onAdd, onEdit } = props;

  const addTooltip = Translation("add-report") || INVALID_TRANSLATION;
  const viewTooltip = Translation("view") || INVALID_TRANSLATION;
  const editTooltip = Translation("edit") || INVALID_TRANSLATION;

  return (
    <>
      {onView && (
        <Tooltip placement="top" title={viewTooltip}>
          <span onClick={onView} className="hp-fs-16 hp-mr-15 pointer">
            <EyeIcon width="18" height="18" />
          </span>
        </Tooltip>
      )}

      {onEdit && (
        <Tooltip placement="top" title={editTooltip}>
          <span onClick={onEdit} className="hp-fs-16 hp-mr-15 pointer">
            <EditIcon width="18" height="18" />
          </span>
        </Tooltip>
      )}

      {onAdd && (
        <Tooltip placement="top" title={addTooltip}>
          <span onClick={onAdd} className="hp-fs-16 hp-mr-15 pointer">
            <AddReport width="18" height="18" />
          </span>
        </Tooltip>
      )}
    </>
  );
}
