export default function PresentationChartIcon(props: any) {
  return (
    <svg
      {...props}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 0.5C0.22386 0.5 0 0.72386 0 1C0 1.27614 0.22386 1.5 0.5 1.5H1V8.5C1 9.6046 1.89543 10.5 3 10.5H3.80629L3.02566 12.8419C2.93833 13.1039 3.07991 13.387 3.34189 13.4743C3.60386 13.5617 3.88702 13.4201 3.97434 13.1581L4.19371 12.5H9.80627L10.0257 13.1581C10.113 13.4201 10.3961 13.5617 10.6581 13.4743C10.9201 13.387 11.0617 13.1039 10.9743 12.8419L10.1937 10.5H11C12.1046 10.5 13 9.6046 13 8.5V1.5H13.5C13.7761 1.5 14 1.27614 14 1C14 0.72386 13.7761 0.5 13.5 0.5H0.5ZM4.86038 10.5H9.1396L9.47293 11.5H4.52705L4.86038 10.5ZM10.2503 4.50311C10.4894 4.36485 10.5711 4.05898 10.4328 3.81995C10.2945 3.58092 9.98867 3.49924 9.74967 3.63751C8.83847 4.16459 8.03447 4.85581 7.3778 5.67073L6.35353 4.6465C6.15827 4.45124 5.84173 4.45124 5.64645 4.6465L3.64645 6.64653C3.45119 6.84173 3.45119 7.15833 3.64645 7.3536C3.84171 7.54887 4.15829 7.54887 4.35355 7.3536L6 5.70713L7.0782 6.78533C7.1828 6.89 7.32847 6.94287 7.47587 6.9298C7.62327 6.91673 7.75733 6.83907 7.84193 6.71767C8.47133 5.81447 9.29427 5.05619 10.2503 4.50311Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
