import moment from "moment";
import AppLocale from "./languages";
import Router from "./router/Router";

import "./languages/moment/en.moment";
import "./languages/moment/fr.moment";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import * as Sentry from "@sentry/react";

import { GOOGLE_MAPS_PLACES_API } from "./utils/const";
import { ViewLoader } from "./view/components";
const loadAsyncScript = (src: any) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");

    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });

    script.addEventListener("load", () => resolve(script));
    script.addEventListener("error", () =>
      reject(new Error("Failed to load script"))
    );
    document.head.appendChild(script);
  });
};

function App() {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const customise: any = useSelector<any>((state: any): any => state.customise);

  // @ts-ignore
  const currentAppLocale: { [key: string]: any } =
    AppLocale[customise.language === "undefined" ? "en" : customise.language];

  const initMapPlacesScript = async () => {
    return loadAsyncScript(GOOGLE_MAPS_PLACES_API)
      .then(() => setScriptLoaded(true))
      .catch((error) => {
        console.error("Failed to load Google Maps script:", error);
      });
  };

  useEffect(() => {
    // @ts-ignore
    document.querySelector("html")?.setAttribute("lang", customise.language);
  }, [customise]);

  useEffect(() => {
    initMapPlacesScript();
  }, []);

  useEffect(() => {
    if (scriptLoaded) {
      initMapPlacesScript();
    }
  }, [scriptLoaded]);

  // @ts-ignore
  moment.locale(customise.language === "en" ? "en-custom" : "fr-custom");

  // Render a loading state while the script is loading
  if (!scriptLoaded) return <ViewLoader />;
  return (
    // @ts-ignore

    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={customise.direction}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Router />
      </IntlProvider>
    </ConfigProvider>
  );
}

export default Sentry.withProfiler(App);
