export default function FileReportIcon(props: any) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 1H6C7.38073 1 8.5 2.11929 8.5 3.5V4.75C8.5 5.44035 9.05967 6 9.75 6H11C12.3807 6 13.5 7.11927 13.5 8.5V13.75C13.5 14.4403 12.9403 15 12.25 15H3.75C3.05965 15 2.5 14.4403 2.5 13.75V2.25C2.5 1.55965 3.05965 1 3.75 1ZM6.5 11.5C6.5 11.2239 6.27614 11 6 11C5.72386 11 5.5 11.2239 5.5 11.5V12C5.5 12.2761 5.72386 12.5 6 12.5C6.27614 12.5 6.5 12.2761 6.5 12V11.5ZM8 9.5C8.27613 9.5 8.5 9.72387 8.5 10V12C8.5 12.2761 8.27613 12.5 8 12.5C7.72387 12.5 7.5 12.2761 7.5 12V10C7.5 9.72387 7.72387 9.5 8 9.5ZM10.5 8.5C10.5 8.22387 10.2761 8 10 8C9.72387 8 9.5 8.22387 9.5 8.5V12C9.5 12.2761 9.72387 12.5 10 12.5C10.2761 12.5 10.5 12.2761 10.5 12V8.5Z"
        fill="#FF6B43"
      />
      <path
        d="M9.49999 3.50009C9.49999 2.62473 9.17866 1.82441 8.64746 1.21069C10.9134 1.80261 12.6975 3.58668 13.2894 5.85263C12.6757 5.32145 11.8754 5.00009 11 5.00009H9.74999C9.61193 5.00009 9.49999 4.88817 9.49999 4.75009V3.50009Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
