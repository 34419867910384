export default function CalendarIcon(props: any) {
  return (
    <svg
      {...props}
      width={props.width || "18px"}
      height={props.height || "18px"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.625 1.5V3.375M13.375 1.5V3.375M1.5 14.625V5.25C1.5 4.21447 2.33947 3.375 3.375 3.375H14.625C15.6605 3.375 16.5 4.21447 16.5 5.25V14.625M1.5 14.625C1.5 15.6605 2.33947 16.5 3.375 16.5H14.625C15.6605 16.5 16.5 15.6605 16.5 14.625M1.5 14.625V8.375C1.5 7.3395 2.33947 6.5 3.375 6.5H14.625C15.6605 6.5 16.5 7.3395 16.5 8.375V14.625M9 9.625H9.00625V9.63125H9V9.625ZM9 11.5H9.00625V11.5062H9V11.5ZM9 13.375H9.00625V13.3812H9V13.375ZM7.125 11.5H7.13125V11.5062H7.125V11.5ZM7.125 13.375H7.13125V13.3812H7.125V13.375ZM5.25 11.5H5.25625V11.5062H5.25V11.5ZM5.25 13.375H5.25625V13.3812H5.25V13.375ZM10.875 9.625H10.8812V9.63125H10.875V9.625ZM10.875 11.5H10.8812V11.5062H10.875V11.5ZM10.875 13.375H10.8812V13.3812H10.875V13.375ZM12.75 9.625H12.7562V9.63125H12.75V9.625ZM12.75 11.5H12.7562V11.5062H12.75V11.5Z"
        stroke={props.stroke || "#313236"}
        strokeWidth={props.strokeWidth || "1.5"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
