import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, Row, Col } from "antd";
//@ts-ignore
import { motion } from "framer-motion/dist/framer-motion";

import MenuLogo from "./logo";
import MenuFooter from "./footer";
import MenuItem from "./item";
import MenuMobile from "./mobile";

const { Sider } = Layout;

export default function Sidebar(props: any) {
  //@ts-ignore
  const customise = useSelector((state) => state.customise);

  const { visible, setVisible } = props;
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (customise.sidebarCollapsed) setCollapsed(true);
    else setCollapsed(false);
  }, [customise]);

  const onClose = () => setVisible(false);

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={316}
      className="hp-sidebar hp-background-is-sidebar hp-bg-color-dark-90 hp-border-right-1 hp-border-color-black-40 hp-border-color-dark-80"
    >
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ type: "spring", duration: 0.5, delay: 0.1 }}
        className="hp-d-flex hp-h-100"
        style={{ flexDirection: "column" }}
      >
        <Row
          style={{ background: "none" }}
          className="sidebar-header"
          align="bottom"
          justify="center"
        >
          <Col>{!collapsed && <MenuLogo onClose={onClose} />}</Col>
        </Row>

        <MenuItem onClose={onClose} />
        <MenuFooter onClose={onClose} collapsed={collapsed} />
        <MenuMobile
          onClose={onClose}
          visible={visible}
          mode={"IS"}
          collapsed={collapsed}
        />
      </motion.div>
    </Sider>
  );
}
