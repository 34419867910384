import axios from "axios";
import Cookies from "universal-cookie";
import { axiosInstance } from "../hooks/useAxiosInterceptor";
const cookies = new Cookies();

// USERS - get external users
export const getUserDetailsById = ({ queryKey }: any) => {
  return axiosInstance({
    method: "get",
    headers: {
      "api-key": process.env.REACT_APP_SERVER_API_KEY,
      // Authorization: `Bearer ${cookies.get("secure-access-token")}`,
    },
    url: `${process.env.REACT_APP_SERVER_URL}/user?userId=${queryKey[1]}`,
  });
};

// Contact PAGE
export const contactPageMethod = ({ message, subject, email }: any) => {
  const data = new FormData();
  data.append("message", message);
  data.append("subject", subject);
  data.append("email", email);

  return axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/contactZupDeco`,
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
    data: data,
  });
};

// Tutor - Fetch On Boarding
export const fetchTutorOnBoarding = () => {
  return axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/tutor/onboarding?userId=${cookies.get("userId")}`,
  });
};

// Tutor - Fetch Sessions
export const fetchTutorSessions = (startDate: string, endDate: string) => {
  return axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/tutor/mySessions?userId=${cookies.get(
      "userId"
    )}&startDate=${startDate}&endDate=${endDate}`,
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
  });
};

// Tutor - Fetch Dashboard
export const fetchTutorDashboard = () => {
  return axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/tutor/dashboard?userId=${cookies.get("userId")}`,
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
  });
};

// Tutor - Fetch Students
export const fetchTutorStudents = () => {
  return axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/tutor/myStudents?userId=${cookies.get("userId")}`,
    headers: {
      "api-key": process.env.REACT_APP_SERVER_API_KEY,
      Authorization: `Bearer ${cookies.get("secure-access-token")}`,
    },
  });
};

// Parent - Fetch Dashboard
export const fetchParentDashboard = () => {
  return axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/parent/dashboard?userId=${cookies.get("userId")}`,
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
  });
};

// Parent - Fetch Children
export const fetchParentChildren = () => {
  return axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/parent/child?parentUserId=${cookies.get("userId")}`,
    // headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
  });
};

// Parent - Send Child invitation
export const sendChildInvitation = ({ childId, identifier }: any) => {
  const data = new FormData();
  data.append("childUserId", childId);
  data.append("firebaseIdentifier", identifier?.replace(/\s/g, ""));

  return axiosInstance({
    method: "post",
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
    url: `${process.env.REACT_APP_SERVER_URL}/parent/send-invitation`,
    data,
  });
};

// Parent - Add Child(student)
export const addChild = ({ child }: any) => {
  const data = new FormData();
  data.append("parentUserId", cookies.get("userId"));
  data.append("student", JSON.stringify(child));

  return axiosInstance({
    method: "post",
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
    url: `${process.env.REACT_APP_SERVER_URL}/parent/child`,
    data,
  });
};

// Parent - get Child(student)
export const getChildDetails = ({ queryKey }: any) => {
  const [_, childId] = queryKey;
  return axiosInstance({
    method: "get",
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
    url: `${process.env.REACT_APP_SERVER_URL}/parent/child/details?userId=${childId}`,
  });
};

// Parent - Update Child(student)
export const updateChild = ({ child }: any) => {
  const data = new FormData();
  data.append("student", JSON.stringify(child));
  data.append("parentUserId", cookies.get("userId"));

  return axiosInstance({
    method: "put",
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
    url: `${process.env.REACT_APP_SERVER_URL}/parent/child`,
    data,
  });
};

// Parent - Fetch Sessions
export const fetchParentSessions = () => {
  return axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/parent/child/sessions?userId=${cookies.get("userId")}`,
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
  });
};

// Parent - Fetch Account Info (myspace)
export const fetchParentMySpace = () => {
  return axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/parent/myspace?userId=${cookies.get("userId")}`,
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
  });
};

// Parent - Update Account Data
export const updateParentMyspace = (parentData: any) => {
  const data = new FormData();
  data.append("userId", cookies.get("userId"));
  data.append("firstName", parentData.firstName);
  data.append("email", parentData.email);
  data.append("lastName", parentData.lastName);
  data.append("dateOfBirth", parentData.dateOfBirth);
  data.append("gender", parentData.gender);
  data.append("telephone", parentData.telephone);
  data.append("address", parentData.address);
  data.append("zipCode", parentData.zipCode);

  return axiosInstance({
    method: "put",
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
    url: `${process.env.REACT_APP_SERVER_URL}/parent/myspace`,
    data,
  });
};

// Student - Fetch Dashboard
export const fetchStudentDashboard = () => {
  return axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/student/dashboard?userId=${cookies.get("userId")}`,
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
  });
};

//  Student - Fetch Sessions
export const fetchStudentSessions = () => {
  return axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/student/session?userId=${cookies.get("userId")}`,
  });
};

export const fetchCoordinatorSessions = () => {
  return axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/sessions/coordinatorCalendar?userId=${cookies.get("userId")}`,
  });
};

// Student - Fetch Account Info (myspace)
export const fetchStudentMySpace = () => {
  return axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/student/myspace?userId=${cookies.get("userId")}`,
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
  });
};

// Student - Update Account Data
export const updateStudentMyspace = (studentData: any) => {
  const data = new FormData();
  data.append("userId", cookies.get("userId"));
  data.append("firstName", studentData.firstName);
  data.append("lastName", studentData.lastName);
  data.append("dateOfBirth", studentData.dateOfBirth);
  data.append("gender", studentData.gender);
  data.append("telephone", studentData.telephone);
  const addressString = JSON.stringify(studentData.address);
  data.append("address", addressString);
  return axiosInstance({
    method: "put",
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
    url: `${process.env.REACT_APP_SERVER_URL}/student/myspace`,
    data,
  });
};

// Check if a user exist in MongoDB
export const checkIfTheUserExistsInDB = ({ queryKey }: any) => {
  const [_, firebaseIdentifier] = queryKey;
  return axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/user/checkIfTheUserExistsInDB?firebaseIdentifier=${firebaseIdentifier}`,
    // headers: {
    //   "api-key": process.env.REACT_APP_SERVER_API_KEY,
    //   Authorization: `Bearer ${cookies.get("secure-access-token")}`,
    // },
  });
};

export const checkIfTheEmailAndNameExists = ({ queryKey }: any) => {
  const [_, query] = queryKey;
  return axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/user/checkIfTheEmailAndNameExists?${query}`,
    headers: {
      "api-key": process.env.REACT_APP_SERVER_API_KEY,
    },
  });
};

// Parent - Update Account Data
export const updateTutorDates = (data: {
  startDate: string;
  endDate: string;
}) => {
  return axiosInstance({
    method: "put",
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/tutor/save-dates?userId=${cookies.get("userId")}`,
    data,
  });
};
// redirect to maxicours
export const redirectToMaxicours = ({
  level,
  userData,
}: {
  level: string;
  userData: any;
}) => {
  return axiosInstance({
    method: "post",
    headers: { "api-key": process.env.REACT_APP_SERVER_API_KEY },
    url: `${process.env.REACT_APP_SERVER_URL}/maxicours?class=${level}`,
    data: userData,
  });
};
