import Line from "../errors/line";
import MenuLogo from "../../layout/components/menu/logo";

import { Helmet } from "react-helmet";
import { Col, Row } from "antd";

export default function DataDeletion() {
  return (
    <Row className="hp-text-center hp-overflow-hidden">
      <Line />

      <Helmet>
        <title>Data Deletion</title>
      </Helmet>

      <Col
        span={24}
        className="hp-d-flex-full-center hp-border-bottom-1 hp-border-color-dark-60 hp-py-32"
      >
        <MenuLogo />
      </Col>

      <Col className="hp-error-content hp-py-32" span={24}>
        <Row className="hp-h-100 text-align-start p-25p hp-mt-40">
          <Col>
            <h2 className="h1 hp-mb-16">Data Deletion</h2>

            <p className="hp-mb-32 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0">
              At ZupDeCo, we place great emphasis on the responsible use of data
              and respect the choices of our users. We understand that some
              users may decide they no longer wish to be a part of our service,
              and in such cases, we are committed to facilitating the process of
              data deletion. If you would like to have your data removed from
              our system, you may submit a request, and we will promptly take
              action to delete your information in accordance with our Data
              Deletion Policy.
              <br />
              <br />
              However, it is important to note that while we will remove the
              majority of your data, we may retain certain anonymized
              information for statistical purposes. This data is essential for
              us to analyze user trends, improve our services, and maintain
              overall platform functionality. The retained data will be stripped
              of any personally identifiable information, ensuring that it
              cannot be linked back to you in any way. If you would like to
              submit a data deletion request or have any questions regarding our
              data retention practices, please contact us at data@zupdeco.org.
              Our team will be more than happy to assist you and address any
              concerns you may have.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
