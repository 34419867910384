import ReactDOM from "react-dom";
import store from "./redux/store";
import App from "./App";
import AuthContextProvider from "./context/AuthContext";
import AppStateProvider from "./context/appState";

import { Suspense } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from "react-redux";

import "./assets/icons/remixicon.css";
import "./assets/less/yoda-theme.less";
import "react-phone-input-2/lib/style.css";

import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import * as Sentry from "@sentry/react";
import Welcoming from "./Welcoming";

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DNS,
//   environment: process.env.NODE_ENV,
//   enabled: process.env.NODE_ENV === "production",
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: [
//         "localhost",
//         `${process.env.REACT_APP_SERVER_URL}`,
//       ],
//     }),
//     new Sentry.Replay(),
//   ],
//   tracesSampleRate: process.env.NODE_ENV === "production" ? 0.5 : 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

const queryClient = new QueryClient();

ReactDOM.render(
  <Suspense fallback="loading">
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <AppStateProvider>
            <AuthContextProvider>
              <App />
            </AuthContextProvider>
          </AppStateProvider>
        </BrowserRouter>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Suspense>,
  // <Welcoming />
  document.getElementById("root")
);
