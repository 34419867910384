export default function EmailCircleIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_2)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="#FFF3F0"
        />
        <path
          d="M9.5 16.6691V25.25C9.5 26.9069 10.8432 28.25 12.5 28.25H27.5C29.1569 28.25 30.5 26.9069 30.5 25.25V16.6691L21.5723 22.1631C20.6081 22.7564 19.3919 22.7564 18.4277 22.1631L9.5 16.6691Z"
          fill="#FF6B43"
        />
        <path
          d="M30.5 14.9078V14.75C30.5 13.0932 29.1569 11.75 27.5 11.75H12.5C10.8432 11.75 9.5 13.0932 9.5 14.75V14.9078L19.2139 20.8856C19.696 21.1823 20.304 21.1823 20.7861 20.8856L30.5 14.9078Z"
          fill="#FF6B43"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_2">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
