import { Button } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import CountUp from "react-countup";
import { Translations } from "../../constants";
export default function FilterFooter(props: any) {
  const { onReset, onFilter, filters, total } = props;
  const intl = useIntl();
  const disabled = Object.values(filters)?.every((v: any) => !v);
  return (
    <div className="card-footer-filter">
      <div>
        <Button
          disabled={disabled}
          type="primary"
          size="small"
          className="hp-px-30 custom-on-hover"
          onClick={() => onFilter()}
        >
          <FormattedMessage id="filter" />
        </Button>

        <Button
          type="text"
          className="hp-text-color-black-80 hp-hover-bg-black-100 hp-px-24 custom-on-hover"
          size="small"
          onClick={() => onReset()}
        >
          <FormattedMessage id="reset-filters" />
        </Button>
      </div>
      {total && (
        <h5>
          Total <CountUp end={total as number} />{" "}
          {filters.mode === "tutor"
            ? Translations(intl).TUTOR_TRANSLATION
            : Translations(intl).STUDENT_TRANSLATION}
        </h5>
      )}
    </div>
  );
}
