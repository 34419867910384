import { FormattedMessage } from "react-intl";

import {
  CalendarIcon,
  HomeIcon,
  QuestionMarkIcon,
  AccountIcon,
  ChildrensIcon,
  ChipIcon,
} from "../../view/icons";

const parentMenu = [
  {
    id: "parent-accueil",
    title: <FormattedMessage id="homepage" />,
    icon: <HomeIcon />,
    navLink: "/",
  },
  {
    id: "parent-enfants",
    title: <FormattedMessage id="my-children" />,
    icon: <ChildrensIcon />,
    navLink: "/parent/children",
  },
  {
    id: "parent-séances",
    title: <FormattedMessage id="the-sessions" />,
    icon: <CalendarIcon />,
    navLink: "/parent/sessions",
  },
  {
    id: "parent-compte",
    title: <FormattedMessage id="my-account" />,
    icon: <AccountIcon />,
    navLink: "/parent/account",
  },
  {
    id: "parent-equipment",
    title: <FormattedMessage id="equipments-tester" />,
    icon: <ChipIcon />,
    navLink: "/parent/equipment",
  },
  {
    id: "parent-contacter",
    title: <FormattedMessage id="contact-zupdeco" />,
    icon: <QuestionMarkIcon />,
    navLink: "/parent/contact-zupdeco",
  },
];

export default parentMenu;
