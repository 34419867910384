import { FormattedMessage } from "react-intl";

import {
  AccountIcon,
  CalendarIcon,
  ChildrensIcon,
  HomeIcon,
  QuestionMarkIcon,
  AcademicHatIcon,
  DocumentsIcon,
  ChipIcon,
  MyReportsIcon,
} from "../../view/icons";

const tutorMenu = [
  {
    id: "tutor-accueil",
    title: <FormattedMessage id="homepage" />,
    icon: <HomeIcon />,
    navLink: "/",
  },
  {
    id: "tutor-élèves",
    title: <FormattedMessage id="my-students" />,
    icon: <ChildrensIcon />,
    navLink: "/tutor/students",
  },
  {
    id: "tutor-séances",
    title: <FormattedMessage id="my-sessions" />,
    icon: <CalendarIcon />,
    navLink: "/tutor/sessions",
  },
  {
    id: "tutor-séances",
    title: <FormattedMessage id="my-sessions-list" />,
    icon: <AcademicHatIcon />,
    navLink: "/tutor/listSessions",
  },
  {
    id: "tutor-reports",
    title: <FormattedMessage id="my-reports" />,
    icon: <MyReportsIcon />,
    navLink: "/tutor/reports",
  },
  {
    id: "tutor-compte",
    title: <FormattedMessage id="my-account" />,
    icon: <AccountIcon />,
    navLink: "/tutor/account",
  },
  {
    id: "tutor-documents",
    title: <FormattedMessage id="my-documents" />,
    icon: <DocumentsIcon />,
    navLink: "/tutor/documents",
  },
  {
    id: "tutor-equipment",
    title: <FormattedMessage id="equipments-tester" />,
    icon: <ChipIcon />,
    navLink: "/tutor/equipment",
  },
  // {
  //   id: "tutor-mon-pjjjarcours",
  //   title: <FormattedMessage id="my-training-path" />,
  //   icon: <ProgressIcon />,
  //   navLink: "/tutor/training-path",
  // },
  {
    id: "tutor-contacter",
    title: <FormattedMessage id="contact-zupdeco" />,
    icon: <QuestionMarkIcon />,
    navLink: "/tutor/contact-zupdeco",
  },
];

export default tutorMenu;
