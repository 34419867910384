export const DisableCache = {
  cacheTime: 0,
  staleTime: 0,
  refetchOnReconnect: false,
  refetchInterval: 0,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: false,
  refetchOnMount: false,
};

export const CsvExports = {
  exportCsv: true,
  pageSize: 99999999,
  page: 1,
};
