import { Col, Row } from "antd";
import { INVALID_TRANSLATION } from "../../utils/const";
import { Translation } from "../../utils";

export default function TabHeader({
  translationKey,
}: {
  translationKey: string;
}) {
  return (
    <div className="header">
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <h5 className="header-title">
            {Translation(translationKey) || INVALID_TRANSLATION}
          </h5>
        </Col>
      </Row>
    </div>
  );
}
