export default function MicIcon(props: any) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 3C5.5 1.61929 6.61929 0.5 8 0.5C9.38073 0.5 10.5 1.61929 10.5 3V8.5C10.5 9.88073 9.38073 11 8 11C6.61929 11 5.5 9.88073 5.5 8.5V3Z"
        fill="#FF6B43"
      />
      <path
        d="M4 7C4.27614 7 4.5 7.22387 4.5 7.5V8.5C4.5 10.433 6.067 12 8 12C9.933 12 11.5 10.433 11.5 8.5V7.5C11.5 7.22387 11.7239 7 12 7C12.2761 7 12.5 7.22387 12.5 7.5V8.5C12.5 10.8163 10.75 12.7238 8.5 12.9725V14.5H10.5C10.7761 14.5 11 14.7239 11 15C11 15.2761 10.7761 15.5 10.5 15.5H5.5C5.22386 15.5 5 15.2761 5 15C5 14.7239 5.22386 14.5 5.5 14.5H7.5V12.9725C5.25003 12.7238 3.5 10.8163 3.5 8.5V7.5C3.5 7.22387 3.72386 7 4 7Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
