import { signOut } from "firebase/auth";
import { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../firebase/firebase";
import { RedirectPaths, UserRoles } from "../utils/const";

import { useLocalStorage } from "usehooks-ts";
import { useLocation } from "react-router-dom";
import { checkEmailValidation } from "../helpers/IS_api";
import { useQuery } from "react-query";
import useAxiosInterceptor from "../hooks/useAxiosInterceptor";

export interface UserData {
  address?: any;
  contactDetails?: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
  createdAt: string;
  dateOfBirth: string | null;
  firebaseIds: { identifier: string /* Add other properties as needed */ }[];
  gender: string;
  iAcceptTermsAndPolicyOfConfidentiality: boolean;
  iAcceptTheCommitmentLetter: boolean;
  iAcceptToBeContactedForParticipatingInAStudy: boolean;
  iAgreeToReceiveInformationAndUpdates: boolean;
  parentHasChildren: boolean;
  status: string;
  userId: string;
  userRole: string;
  _id: string;
  createdBy: string;
  // Add other properties as needed
}

const current = new Date();
const AuthContext = createContext({});
// useAuthContext custom hook gives us the useContext hook with AuthContext provided to it.
export const useAuthContext = () => useContext<any>(AuthContext);

// AuthContext component that we will use as wrapper to those components that should consume the User Context API
const AuthContextProvider = ({ children }: any) => {
  const nextYear = new Date();
  nextYear.setFullYear(current.getFullYear() + 1);
  const location = useLocation();
  const [enableValidationCheck, setEnableValidationCheck] =
    useState<boolean>(false);

  const [userData, setUserData] = useState<any>({});
  const { removeAllCookies, getUserIdCookies } = useAxiosInterceptor();
  const userId = getUserIdCookies();
  const [enableCheck, setEnableCheck] = useLocalStorage("enableCheck", true);

  useEffect(() => {
    if (
      enableCheck &&
      !!userId &&
      (location.pathname.includes("/tutor/register") ||
        location.pathname.includes("/parent/register") ||
        location.pathname.includes("/student/register"))
    ) {
      setEnableValidationCheck(true);
    }
  }, [userId, location.pathname, enableValidationCheck, enableCheck]);

  useQuery({
    queryKey: ["checkEmailValidation", userId, location.pathname],
    queryFn: () => checkEmailValidation(userId),
    refetchInterval: 3000,
    enabled: enableValidationCheck,
    onSuccess: ({ data }) => {
      if (data.isEmailVerified) {
        console.log("EMAIL IS VERIFIED");
        setEnableCheck(false);
        switch (location.pathname) {
          case "/parent/register":
            window.location.href = "/parent/login";
            break;
          case "/tutor/register":
            window.location.href = `/tutor/register?step=3&userId=${userId}`;
            break;
          case "/student/register":
            window.location.href = "/student/login";
            break;
        }
      }
    },
    onError: (error: any) => {
      console.log("error", error);
      setEnableCheck(false);
    },
  });

  const logoutUser = async (user: any, history: any) => {
    await signOut(auth).then(() => {
      removeAllCookies();
      localStorage.removeItem("user");
      sessionStorage.clear();
      switch (user?.userRole) {
        case UserRoles.TUTOR:
          history?.push(RedirectPaths.TUTOR_LOGIN);
          break;

        case UserRoles.PARENT:
          history?.push(RedirectPaths.PARENT_LOGIN);
          break;

        case UserRoles.STUDENT:
          history?.push(RedirectPaths.STUDENT_LOGIN);
          break;

        case UserRoles.ADMIN:
          history?.push(RedirectPaths.IS_LOGIN);
          break;

        case UserRoles.MANAGER:
          history?.push(RedirectPaths.IS_LOGIN);
          break;

        case UserRoles.COORDINATOR:
          history?.push(RedirectPaths.IS_LOGIN);
          break;

        case UserRoles.VSC:
          history?.push(RedirectPaths.IS_LOGIN);
          break;
      }
    });
  };
  const value = {
    logoutUser,
    setUserData,
    setEnableValidationCheck,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
