export default function TrashCircleIcon() {
  return (
    <svg
      className="remix-icon"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#FFEDEF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5001 12.4782V12.705C25.4548 12.7924 26.4017 12.9073 27.3398 13.049C27.6871 13.1014 28.0332 13.1575 28.3781 13.2173C28.7863 13.288 29.0598 13.6762 28.9891 14.0843C28.9184 14.4924 28.5302 14.766 28.1221 14.6953C28.0525 14.6832 27.9829 14.6713 27.9131 14.6596L26.9077 27.7301C26.7875 29.2931 25.4842 30.5 23.9166 30.5H16.0837C14.5161 30.5 13.2128 29.2931 13.0925 27.7301L12.0871 14.6596C12.0174 14.6713 11.9477 14.6832 11.8781 14.6953C11.47 14.766 11.0818 14.4924 11.0111 14.0843C10.9404 13.6762 11.214 13.288 11.6221 13.2173C11.967 13.1575 12.3131 13.1014 12.6605 13.049C13.5986 12.9073 14.5454 12.7924 15.5001 12.705V12.4782C15.5001 10.9137 16.7126 9.57818 18.3156 9.52691C18.8749 9.50901 19.4365 9.5 20.0001 9.5C20.5638 9.5 21.1253 9.50901 21.6847 9.52691C23.2876 9.57818 24.5001 10.9137 24.5001 12.4782ZM18.3635 11.0261C18.9069 11.0088 19.4525 11 20.0001 11C20.5478 11 21.0934 11.0088 21.6367 11.0261C22.3913 11.0503 23.0001 11.6839 23.0001 12.4782V12.5908C22.0078 12.5306 21.0075 12.5 20.0001 12.5C18.9928 12.5 17.9925 12.5306 17.0001 12.5908V12.4782C17.0001 11.6839 17.6089 11.0503 18.3635 11.0261ZM18.0087 16.9712C17.9928 16.5573 17.6444 16.2346 17.2304 16.2506C16.8165 16.2665 16.4939 16.6149 16.5098 17.0288L16.856 26.0288C16.8719 26.4427 17.2203 26.7654 17.6343 26.7494C18.0482 26.7335 18.3708 26.3851 18.3549 25.9712L18.0087 16.9712ZM23.4895 17.0288C23.5054 16.6149 23.1828 16.2665 22.7689 16.2506C22.355 16.2346 22.0065 16.5573 21.9906 16.9712L21.6444 25.9712C21.6285 26.3851 21.9512 26.7335 22.3651 26.7494C22.779 26.7654 23.1274 26.4427 23.1433 26.0288L23.4895 17.0288Z"
        fill="#FF0022"
      />
    </svg>
  );
}
