export default function DocumentsIcon(props: any) {
  return (
    <svg
      {...props}
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.25 10.875V8.6875C13.25 7.1342 11.9908 5.875 10.4375 5.875H9.1875C8.66975 5.875 8.25 5.45527 8.25 4.9375V3.6875C8.25 2.1342 6.99083 0.875 5.4375 0.875H3.875M4.5 12.75V13.375M7 10.875V13.375M9.5 9V13.375M5.75 0.875H1.6875C1.16973 0.875 0.75 1.29473 0.75 1.8125V16.1875C0.75 16.7052 1.16973 17.125 1.6875 17.125H12.3125C12.8302 17.125 13.25 16.7052 13.25 16.1875V8.375C13.25 4.23287 9.89217 0.875 5.75 0.875Z"
        stroke="#323136"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
