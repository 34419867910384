import { lazy } from "react";
import { RedirectPaths } from "../../utils/const";

const SessionPages = [
  {
    path: `/session/:sessionId`,
    component: lazy(
      () =>
        import(
          "../../view/InternalSystem/tutoring/sessions/join_session/index"
        ),
    ),
    layout: "FullLayout",
    redirectPath: RedirectPaths.IS_LOGIN,
  },
];

export default SessionPages;
