import { AutoComplete, Form } from "antd";
import { FormattedMessage } from "react-intl";
import { Translation } from "../../utils";
import { Required } from "../../utils/Forms";

const Option = AutoComplete.Option;

export default function AutoCompleteSelectInput(props: any) {
  const {
    labelName,
    name,
    isRequired,
    loading,
    list,
    mapValue,
    mapName,
    className,
    setSearch,
    setTarget,
    targetName,
  } = props;

  const selectHandler = (option: any) =>
    setTarget({
      userId: option.key,
      [targetName]: option.value,
    });

  return (
    <div className={className || "row"}>
      <Form.Item
        rules={[{ required: isRequired, message: Required(labelName) }]}
        label={<FormattedMessage id={labelName} />}
        name={name}
        className="hp-mb-0"
      >
        <AutoComplete
          placeholder={`${Translation("choose")} ${Translation(labelName)}`}
          disabled={loading}
          className="hp-w-100"
          size="large"
          showArrow={true}
          onSearch={(searchText: string) => setSearch(searchText)}
          onSelect={(value: any, option: any) => selectHandler(option)}
        >
          {list.map((item: any) => {
            return (
              <Option
                value={item[mapName[0]] + " " + item[mapName[1]]}
                key={item[mapValue]}
              >
                {item[mapName[0]] + " " + item[mapName[1]]}
              </Option>
            );
          })}
        </AutoComplete>
      </Form.Item>
    </div>
  );
}
