export default function EmailCircleVerifiedIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#F0FFE8" />
      <rect x="8" y="8" width="24" height="24" rx="12" fill="#62C53F" />
      <path d="M14 19.5L18 23.5L25.5 16" stroke="white" strokeWidth="2" />
    </svg>
  );
}
