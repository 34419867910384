export default function StudentIcon(props: any) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 4C10.5 5.38071 9.38073 6.5 8 6.5C6.6193 6.5 5.50001 5.38071 5.50001 4C5.50001 2.61929 6.6193 1.5 8 1.5C9.38073 1.5 10.5 2.61929 10.5 4Z"
        stroke="#FF6B43"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.78536 2.25C9.33163 2.71284 8.69936 3 8 3C7.30066 3 6.66838 2.71284 6.21465 2.25C6.66838 1.78716 7.30066 1.5 7.99999 1.5C8.69935 1.5 9.33163 1.78716 9.78536 2.25Z"
        fill="#FF6B43"
      />
      <path
        d="M9.78536 2.25L10.3209 2.77503C10.6068 2.48339 10.6068 2.01661 10.3209 1.72497L9.78536 2.25ZM6.21465 2.25L5.67908 1.72497C5.39318 2.01661 5.39318 2.48339 5.67907 2.77503L6.21465 2.25ZM9.24979 1.72497C8.93131 2.04984 8.48971 2.25 8 2.25V3.75C8.909 3.75 9.73196 3.37584 10.3209 2.77503L9.24979 1.72497ZM8 2.25C7.51031 2.25 7.06871 2.04985 6.75023 1.72497L5.67907 2.77503C6.26805 3.37583 7.09101 3.75 8 3.75V2.25ZM6.75023 2.77503C7.06871 2.45015 7.5103 2.25 7.99999 2.25V0.75C7.09101 0.75 6.26805 1.12417 5.67908 1.72497L6.75023 2.77503ZM7.99999 2.25C8.48971 2.25 8.93131 2.45016 9.24979 2.77503L10.3209 1.72497C9.73196 1.12416 8.909 0.75 7.99999 0.75V2.25Z"
        fill="#FF6B43"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8C4.99463 8 2.55237 10.4104 2.50081 13.4035C2.49739 13.6021 2.61175 13.7838 2.79219 13.8666C4.37827 14.5945 6.14255 15 8.0002 15C9.85767 15 11.6218 14.5945 13.2077 13.8668C13.3882 13.784 13.5025 13.6023 13.4991 13.4037C13.4477 10.4105 11.0054 8 8 8ZM7.33333 10L6.66667 14.3333C7.69979 14.4981 8.28572 14.4896 9.33333 14.3333L8.66667 10H7.33333Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
