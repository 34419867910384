export default function LinkIcon(props: any) {
  return (
    <svg
      {...props}
      width={props.width || "14px"}
      height={props.height || "14px"}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 0.5H13C13.1326 0.5 13.2598 0.55268 13.3535 0.646447C13.4473 0.740213 13.5 0.867393 13.5 1V4.5C13.5 4.77614 13.2761 5 13 5C12.7239 5 12.5 4.77614 12.5 4.5V2.20711L4.35355 10.3535C4.15829 10.5488 3.84171 10.5488 3.64645 10.3535C3.45119 10.1583 3.45119 9.84173 3.64645 9.64647L11.7929 1.5H9.5C9.22387 1.5 9 1.27614 9 1C9 0.72386 9.22387 0.5 9.5 0.5ZM2.5 3.5C1.94771 3.5 1.5 3.94771 1.5 4.5V11.5C1.5 12.0523 1.94771 12.5 2.5 12.5H9.5C10.0523 12.5 10.5 12.0523 10.5 11.5V6C10.5 5.72387 10.7239 5.5 11 5.5C11.2761 5.5 11.5 5.72387 11.5 6V11.5C11.5 12.6046 10.6046 13.5 9.5 13.5H2.5C1.39543 13.5 0.5 12.6046 0.5 11.5V4.5C0.5 3.39543 1.39543 2.5 2.5 2.5H8C8.27613 2.5 8.5 2.72386 8.5 3C8.5 3.27614 8.27613 3.5 8 3.5H2.5Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
