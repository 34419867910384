export default function SingleUserIcon(props: any) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.125 5C13.125 6.72589 11.7259 8.125 9.99998 8.125C8.27412 8.125 6.875 6.72589 6.875 5C6.875 3.27411 8.27412 1.875 9.99998 1.875C11.7259 1.875 13.125 3.27411 13.125 5Z"
        stroke="#FFC700"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2317 2.8125C11.6645 3.39105 10.8742 3.75 9.99999 3.75C9.12582 3.75 8.33547 3.39105 7.76831 2.8125C8.33547 2.23395 9.12582 1.875 9.99999 1.875C10.8742 1.875 11.6645 2.23395 12.2317 2.8125Z"
        fill="#FFC700"
      />
      <path
        d="M12.2317 2.8125L12.7673 3.33753C13.0532 3.04589 13.0532 2.57911 12.7673 2.28747L12.2317 2.8125ZM7.76831 2.8125L7.23274 2.28747C6.94684 2.57911 6.94684 3.04589 7.23274 3.33753L7.76831 2.8125ZM11.6961 2.28747C11.2642 2.72805 10.6645 3 9.99999 3V4.5C11.0838 4.5 12.0649 4.05405 12.7673 3.33753L11.6961 2.28747ZM9.99999 3C9.33547 3 8.7358 2.72806 8.30389 2.28747L7.23274 3.33753C7.93514 4.05404 8.91617 4.5 9.99999 4.5V3ZM8.30389 3.33753C8.7358 2.89694 9.33547 2.625 9.99999 2.625V1.125C8.91617 1.125 7.93514 1.57095 7.23274 2.28747L8.30389 3.33753ZM9.99999 2.625C10.6645 2.625 11.2642 2.89695 11.6961 3.33753L12.7673 2.28747C12.0649 1.57095 11.0838 1.125 9.99999 1.125V2.625Z"
        fill="#FFC700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10C6.24329 10 3.19046 13.013 3.12601 16.7544C3.12173 17.0026 3.26468 17.2297 3.49024 17.3333C5.47283 18.2431 7.67818 18.75 10.0003 18.75C12.3221 18.75 14.5272 18.2432 16.5097 17.3335C16.7352 17.23 16.8782 17.0028 16.8739 16.7547C16.8097 13.0131 13.7568 10 10 10ZM10.8333 14.375C10.4882 14.375 10.2083 14.6548 10.2083 15C10.2083 15.3452 10.4882 15.625 10.8333 15.625H13.3333C13.6785 15.625 13.9583 15.3452 13.9583 15C13.9583 14.6548 13.6785 14.375 13.3333 14.375H10.8333Z"
        fill="#FFC700"
      />
    </svg>
  );
}
