import moment from "moment";
import {
  Gender,
  StudentStatus,
  UserProgramList,
  UserRoles,
  UserStatus,
} from "./../utils/const";

const today = moment();
const startDate = today.clone().startOf("day");
const endDate = today.clone().add(9, "months").endOf("day");

export const InitialParentAdd = {
  status: UserStatus.ACTIVE,
  contactDetails: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  },
  address: {
    addressLine1: "",
    zipCode: "",
    city: "",
    country: "",
  },
  dateOfBirth: null,
  gender: Gender.MALE,
  parentPreference: {
    comments: "",
    department: {
      departmentName: "",
      departmentId: "",
    },
  },
};

export const InitalStudentState = {
  dateOfBirth: null,
  gender: Gender.MALE,
  status: UserStatus.ON_HOLD,
  contactDetails: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  },
  address: { addressLine1: "" },
  studentPreferences: {
    availability: [],
    userStatus: UserStatus.ON_HOLD,
    situation: StudentStatus.NEW,
    parentUserId: "",
    comments: "",
    typeOfStudent: null,
    doesYourChildNeedHelpWith: null,
    isPartner: false,
    partner: null,
    program: null,
    assignment: {
      tutoringType: ["private", "group"],
      subjectToStudy: null,
      level: {
        levelName: null,
        levelDescription: null,
        _id: null,
      },
      establishments: [],
      sectors: [],
      vsc: [
        {
          vscId: "",
          vscName: "",
        },
      ],
      classes: [],
    },
  },
};

export const InitialTutorState = {
  gender: Gender.MALE,
  userRole: UserRoles.TUTOR,
  status: UserStatus.ON_HOLD,
  address: { addressLine1: "" },
  tutorPreferences: {
    typeOfTutor: null,
    attendInformationMeeting: true,
    subjectToTeach: null,
    documents: [],
    availability: [],
    education: [],
    program: null,
    assignment: {
      establishments: [],
      statusOfTutor: UserRoles.STUDENT,
      level: {
        levelName: null,
        levelDescription: null,
        _id: null,
      },
      status: UserStatus.ON_HOLD,
      tutoringType: ["private", "group"],
      numberOfStudentsToSupport: null,
      typeOfTutor: null,
      isYourCommitmentValuedByYourInstitution: true,
      strongAreas: null,
      sectors: [],
      vsc: [],
    },
    activity: {
      areaOfStudy: "",
      comments: "",
      student: {
        ufrCurriculum: null,
        areaOfStudy: null,
        employeeRetireeOrJobSeeker: true,
        higherEducationEstablishment: {
          establishmentName: null,
          establishmentId: null,
        },
      },
    },
    commitment: {
      endDate: moment(endDate.toISOString()),
      preemptiveEndDate: moment(startDate.toISOString()),
      motiveForPreemptiveEnd: "",
      startDate: moment(startDate.toISOString()),
    },
    contactDetails: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
    },
  },
};
