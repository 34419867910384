export default function TutorsIcon(props: any) {
  return (
    <svg
      {...props}
      width={props.width || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 4.5C5.5 3.11929 6.61929 2 8 2C9.38073 2 10.5 3.11929 10.5 4.5C10.5 5.88071 9.38073 7 8 7C6.61929 7 5.5 5.88071 5.5 4.5Z"
        fill="#FF6B43"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 6.5C10.5 5.39543 11.3954 4.5 12.5 4.5C13.6046 4.5 14.5 5.39543 14.5 6.5C14.5 7.6046 13.6046 8.5 12.5 8.5C11.3954 8.5 10.5 7.6046 10.5 6.5Z"
        fill="#FF6B43"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 6.5C1.5 5.39543 2.39543 4.5 3.5 4.5C4.60457 4.5 5.5 5.39543 5.5 6.5C5.5 7.6046 4.60457 8.5 3.5 8.5C2.39543 8.5 1.5 7.6046 1.5 6.5Z"
        fill="#FF6B43"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.20657 10.0783C5.00522 8.8296 6.40557 8 8 8C9.5946 8 10.9951 8.8298 11.7937 10.0788C12.3458 10.9423 12.5847 11.9835 12.4727 12.9986C12.4551 13.1573 12.3629 13.298 12.2245 13.3775C10.9796 14.0917 9.53673 14.5 8 14.5C6.46328 14.5 5.02039 14.0917 3.7755 13.3775C3.63706 13.298 3.54487 13.1573 3.52735 12.9986C3.41529 11.9833 3.65427 10.9419 4.20657 10.0783Z"
        fill="#FF6B43"
      />
      <path
        d="M3.38819 9.50195C3.38016 9.51429 3.37218 9.52662 3.36425 9.53902C2.72061 10.5454 2.42605 11.744 2.51647 12.9255C2.11111 12.864 1.7178 12.7649 1.34029 12.632L1.26365 12.605C1.12384 12.5557 1.02615 12.4288 1.01439 12.281L1.00795 12.2C1.00268 12.1338 1 12.0669 1 11.9995C1 10.6562 2.05937 9.56042 3.38819 9.50195Z"
        fill="#FF6B43"
      />
      <path
        d="M13.4837 12.9255C13.5741 11.7442 13.2797 10.5459 12.6363 9.53962C12.6283 9.52702 12.6202 9.51449 12.6121 9.50195C13.9407 9.56055 15 10.6564 15 11.9995C15 12.0669 14.9973 12.1338 14.9921 12.2L14.9856 12.281C14.9739 12.4288 14.8761 12.5557 14.7363 12.605L14.6597 12.632C14.2823 12.7649 13.8891 12.8639 13.4837 12.9255Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
