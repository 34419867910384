export default function EditIcon(props: any) {
  return (
    <svg
      {...props}
      className={props.className || "remix-icon hp-text-color-primary-1"}
      width={props.width || "20px"}
      height={props.height || "20px"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0514 3.73889L16.25 5.93749M15 11.6667V15.625C15 16.6605 14.1605 17.5 13.125 17.5H4.375C3.33947 17.5 2.5 16.6605 2.5 15.625V6.87499C2.5 5.83946 3.33947 4.99999 4.375 4.99999H8.33333M14.0514 3.73889L15.4577 2.33264C16.0678 1.72245 17.0572 1.72245 17.6673 2.33264C18.2776 2.94284 18.2776 3.93216 17.6673 4.54236L8.8185 13.3913C8.37792 13.8318 7.83453 14.1557 7.23741 14.3335L5 15L5.66648 12.7626C5.84436 12.1655 6.16821 11.6221 6.60877 11.1815L14.0514 3.73889Z"
        stroke="#FF6B43"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
