import tutor from "./tutor";
import parent from "./parent";
import student from "./student";
import admin from "./admin";
import manager from "./manager";
import coordinator from "./coordinator";
import vsc from "./vsc";

const navigation = { tutor, parent, student, admin, manager, coordinator, vsc };
export default navigation;
