import { Badge } from "antd";
import { AbsenceStatusColors } from "../../utils/const";
import { Translation } from "../../utils";

export default function AbsenceStatusBadge(status: any) {
  let translationKey =
    status === true
      ? "no"
      : status === false
        ? "yes"
        : "absence-not-confirmed-from-tutor";
  let statusKey =
    status === true
      ? "notPresent"
      : status === false
        ? "present"
        : "notReported";
  let statusTranslation = Translation(translationKey);

  let statusColor = AbsenceStatusColors[statusKey] || "default";

  return <Badge status={statusColor} text={statusTranslation} />;
}
