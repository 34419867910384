import { FC, useEffect, useState } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { allowed_routes, allowed_routes_end_slash } from "./allowedRoutes";
import { PrivateRouteProps } from "../ts/types";
import { UserCreatedBy, UserRoles, UserStatus } from "../utils/const";
import { UserData } from "../context/AuthContext";
import { useLocalStorage } from "usehooks-ts";
import { getUserDetailsById } from "../helpers/api";
import { useQueryHook } from "../hooks/useQueyHook";

const PrivateRoute: FC<PrivateRouteProps> = ({
  path,
  redirectPath = "/IS/login",
  ...rest
}) => {
  const currentLocation = useLocation();
  const [user] = useLocalStorage<UserData | null>("user", null);
  const [redirectTo, setRedirectTo] = useState<string | null>(null);
  useQueryHook({
    queryKey: ["current-user", user?.userId],
    queryFn: getUserDetailsById,
    queryOptions: {
      onSuccess: (data: any) => {
        console.log("current user data", data);
        if (
          user?.userRole === UserRoles.TUTOR &&
          data.data.data.status !== UserStatus.ACTIVE &&
          user?.createdBy !== UserCreatedBy.ADMIN &&
          data.data.data.status !== UserStatus.COMMITMENT_END &&
          data.data.data.status !== UserStatus.PREEMPTIVE_END
        ) {
          setRedirectTo(`/${UserRoles.TUTOR}/on-boarding`);
          return;
        }

        if (
          user?.userRole === UserRoles.PARENT &&
          data.data.data.status !== UserStatus.ACTIVE
        ) {
          setRedirectTo(`/${UserRoles.PARENT}/on-boarding`);
          return;
        }
        if (
          user?.userRole === UserRoles.PARENT &&
          data.data.data.status === UserStatus.ACTIVE &&
          currentLocation.pathname === `/${UserRoles.PARENT}/on-boarding`
        ) {
          setRedirectTo(`/`);
          return;
        }
        if (
          user?.userRole === UserRoles.PARENT &&
          data.data.data.status !== UserStatus.ACTIVE &&
          user?.parentHasChildren
        ) {
          setRedirectTo(`/${UserRoles.PARENT}/children`);
          return;
        }
      },
      onError: (error: any) => {
        console.error("Error fetching user details:", error);
      },
    },
  });
  // useEffect(() => {
  //   const checkRedirects = async () => {
  //     if (user) {
  //       console.log("user", user);

  //       try {
  //         const {
  //           data: { data },
  //         } = await getUserDetailsById({
  //           queryKey: [null, user.userId],
  //         });

  //         if (
  //           user.userRole === UserRoles.TUTOR &&
  //           data.status !== UserStatus.ACTIVE &&
  //           user.createdBy !== UserCreatedBy.ADMIN &&
  //           data.status !== UserStatus.COMMITMENT_END &&
  //           data.status !== UserStatus.PREEMPTIVE_END
  //         ) {
  //           setRedirectTo(`/${UserRoles.TUTOR}/on-boarding`);
  //           return;
  //         }

  //         if (
  //           user.userRole === UserRoles.PARENT &&
  //           data.status !== UserStatus.ACTIVE
  //         ) {
  //           setRedirectTo(`/${UserRoles.PARENT}/on-boarding`);
  //           return;
  //         }
  //         if (
  //           user.userRole === UserRoles.PARENT &&
  //           data.status === UserStatus.ACTIVE &&
  //           currentLocation.pathname === `/${UserRoles.PARENT}/on-boarding`
  //         ) {
  //           setRedirectTo(`/`);
  //           return;
  //         }
  //         if (
  //           user.userRole === UserRoles.PARENT &&
  //           data.status !== UserStatus.ACTIVE &&
  //           user.parentHasChildren
  //         ) {
  //           setRedirectTo(`/${UserRoles.PARENT}/children`);
  //           return;
  //         }
  //       } catch (error) {
  //         console.error("Error fetching user details:", error);
  //       }
  //     }
  //   };

  //   checkRedirects();
  // }, [user]);

  if (redirectTo) {
    return (
      <>
        <Redirect to={redirectTo} />
        <Route {...rest} />
      </>
    );
  }

  if (
    !user?.userId &&
    (allowed_routes.includes(currentLocation.pathname) ||
      allowed_routes_end_slash.includes(currentLocation.pathname))
  ) {
    return <Route {...rest} />;
  }

  if (
    user?.userId &&
    (allowed_routes.includes(currentLocation.pathname) ||
      allowed_routes_end_slash.includes(currentLocation.pathname))
  ) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return user?.userId ? (
    <Route {...rest} />
  ) : (
    <Redirect to={{ pathname: redirectPath }} />
  );
};

export default PrivateRoute;
