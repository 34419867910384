export default function ClockIcon(props: any) {
  return (
    <svg
      {...props}
      width={props.width || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM8.5 4C8.5 3.72386 8.27613 3.5 8 3.5C7.72387 3.5 7.5 3.72386 7.5 4V8C7.5 8.27613 7.72387 8.5 8 8.5H11C11.2761 8.5 11.5 8.27613 11.5 8C11.5 7.72387 11.2761 7.5 11 7.5H8.5V4Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
