import { Button, Col } from "antd";
import React from "react";
import { ExportCsvIcon } from "../icons";
import { FormattedMessage } from "react-intl";
interface CsvProps {
  hideExportCsv: boolean;
  loadingCsv: boolean;
  disabled: boolean;
  download: () => void;
}
const DownloadCsv = ({
  hideExportCsv,
  loadingCsv,
  disabled,
  download,
}: CsvProps) => {
  return (
    <div>
      {hideExportCsv === false && (
        <Col
          className={`${loadingCsv || disabled ? "disabled opacity-50" : ""}`}
        >
          <Button
            icon={<ExportCsvIcon />}
            size="small"
            onClick={() => download()}
            loading={loadingCsv}
            className={`btn-gray btn-shadow hp-mb-20`}
          >
            <FormattedMessage id="export-csv" />
          </Button>
        </Col>
      )}
    </div>
  );
};

export default DownloadCsv;
