import { EMPTY_VALUE } from "./const";

export default function CheckPhoneNumber(
  phoneNumber: string,
  skipEmptyValue: boolean = false,
) {
  if (skipEmptyValue === false) {
    if (phoneNumber === undefined) return EMPTY_VALUE;
    if (phoneNumber?.trim() === "") return EMPTY_VALUE;
    else {
      if (phoneNumber?.startsWith("+")) return phoneNumber;
      else return `+${phoneNumber}`;
    }
  } else {
    if (
      phoneNumber === undefined ||
      phoneNumber === "+" ||
      phoneNumber.trim() === "" ||
      phoneNumber === null
    )
      return "";
    else {
      if (phoneNumber?.startsWith("+")) return phoneNumber;
      else return `+${phoneNumber}`;
    }
  }
}
