import logo from "../../../../assets/images/logo/logo.svg";
import logoDark from "../../../../assets/images/logo/logo-dark.svg";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function MenuLogo(props: any) {
  //@ts-ignore
  const customise = useSelector((state) => state.customise);

  return (
    <div className="hp-header-logo hp-d-flex hp-align-items-center">
      <Link
        to="/"
        onClick={props.onClose}
        className="hp-position-relative hp-d-flex"
      >
        {props.small ? (
          customise.theme == "light" ? (
            <LightLogo />
          ) : (
            <DarkLogo />
          )
        ) : customise.direction == "rtl" ? (
          customise.theme == "light" ? (
            <LightLogo />
          ) : (
            <DarkLogo />
          )
        ) : customise.theme == "light" ? (
          <LightLogo />
        ) : (
          <DarkLogo />
        )}
      </Link>
    </div>
  );
}

const LightLogo = () => <img className="hp-logo" src={logo} alt="logo" />;
const DarkLogo = () => <img className="hp-logo" src={logoDark} alt="logo" />;
