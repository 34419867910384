import { useQuery } from "react-query";
import Cookies from "universal-cookie";
import { DisableCache } from "../constants/Props";

const cookies = new Cookies();
type QueryParams = {
  queryKey: any[];
  queryFn: any;
  queryOptions: any;
};
export const useQueryHook: any = ({
  queryKey,
  queryFn,
  queryOptions,
}: QueryParams) => {
  return useQuery(queryKey, queryFn, {
    ...queryOptions,
    enabled:
      !!cookies.get("secure-role-token") &&
      !!cookies.get("secure-access-token") &&
      !!cookies.get("userId") &&
      queryOptions?.enabled,
    ...DisableCache,
  });
};
