import { useState, useRef, useEffect } from "react";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { Link, useHistory } from "react-router-dom";
import { Avatar } from "antd";
import { Dropdown, Col, Row } from "antd";
import { FormattedMessage } from "react-intl";
import { useAuthContext } from "../../../context/AuthContext";
import { EMPTY_VALUE, EMPTY_VALUE_SHORT } from "../../../utils/const";

export default function HeaderUser() {
  const history = useHistory();
  const [user] = useLocalStorage("user", {});
  const { logoutUser } = useAuthContext();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleToggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleDocumentClick = (event: any) => {
    if (
      dropdownVisible &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [dropdownVisible]);

  const handleLogout = () => {
    logoutUser(user, history);

    setDropdownVisible(false);
  };

  const menu = (
    <div className="hp-user-dropdown dropdown-header-radius hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-py-24 hp-px-18 hp-mt-16">
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Link
            to={`/${user.userRole}/account`}
            className="hp-p1-body hp-font-weight-500 hp-hover-text-color-primary-2"
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              <i
                className="ri-user-line"
                style={{ marginRight: "10px", fontSize: "18px" }}
              />
              <FormattedMessage id="my-account" />
            </span>
          </Link>
        </Col>
        <Col span={24}>
          <Link
            to=""
            className="hp-p1-body hp-font-weight-500 hp-hover-text-color-primary-2"
            onClick={handleLogout}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              <i
                className="ri-logout-box-r-line"
                style={{ marginRight: "10px", fontSize: "18px" }}
              />
              <FormattedMessage id="logout" />
            </span>
          </Link>
        </Col>
      </Row>
    </div>
  );

  return (
    <Col>
      <Dropdown
        overlay={menu}
        placement="bottomLeft"
        open={dropdownVisible}
        onOpenChange={handleToggleDropdown}
      >
        <div
          className="hp-border-radius-xl hp-cursor-pointer"
          style={{
            display: "flex",
            alignItems: "center",
            height: 40,
            marginLeft: "10px",
          }}
          ref={dropdownRef}
          onClick={handleToggleDropdown}
        >
          <span
            className="hp-font-weight-500"
            style={{ display: "inline-block", marginRight: "7px" }}
          >
            {user?.contactDetails?.firstName || EMPTY_VALUE}{" "}
            {user?.contactDetails?.lastName || EMPTY_VALUE}
          </span>

          {user?.profilePic ? (
            <div
              className="hp-border-radius-lg hp-overflow-hidden hp-bg-info-4 hp-m-4 hp-d-flex"
              style={{
                minWidth: 40,
                width: 40,
                height: 40,
                borderRadius: "100%",
              }}
            >
              <img src={user?.profilePic} alt="User" height="100%" />
            </div>
          ) : (
            <Avatar
              style={{ backgroundColor: "#FFBFAD", color: "#2D3436" }}
              size={40}
            >
              {user?.contactDetails?.firstName
                ? user?.contactDetails?.firstName.charAt(0)
                : EMPTY_VALUE_SHORT}
              {user?.contactDetails?.lastName
                ? user?.contactDetails?.lastName.charAt(0)
                : EMPTY_VALUE_SHORT}
            </Avatar>
          )}
        </div>
      </Dropdown>
    </Col>
  );
}
