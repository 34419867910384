export default function AddReport(props: any) {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      className="remix-icon"
      height={props.height || "24px"}
      width={props.width || "24px"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path fill="none" d="M0 0h24v24H0z"></path>
        <path
          d="M15 4H5v16h14V8h-4V4zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992zM11 11V8h2v3h3v2h-3v3h-2v-3H8v-2h3z"
          fill="#FF6B43"
        ></path>
      </g>
    </svg>
  );
}
