import Line from "../line";
import Header from "../header";

import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Row, Col, Button } from "antd";

export default function Error404() {
  return (
    <Row className="hp-text-center hp-overflow-hidden">
      <Line />

      <Header />

      <Col className="hp-error-content hp-py-32" span={24}>
        <Row className="hp-h-100" align="middle" justify="center">
          <Col>
            <h1 className="hp-error-content-title hp-font-weight-300 hp-text-color-black-bg hp-text-color-dark-0 hp-mb-0">
              404
            </h1>

            <h2 className="h1 hp-mb-16">
              <FormattedMessage id="page-not-found" />
            </h2>

            <p className="hp-mb-32 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0">
              <FormattedMessage id="page-not-found-description" />
            </p>

            <Link to="/">
              <Button type="primary">
                <FormattedMessage id="back-to-home" />
              </Button>
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
