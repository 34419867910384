export default function RemoveIcon(props: any) {
  return (
    <svg
      {...props}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4933 18.5785C13.3069 22.181 6.99318 21.7077 3.39071 17.5213C-0.211759 13.3349 0.261561 7.02121 4.44794 3.41874C8.63431 -0.183732 14.948 0.289588 18.5505 4.47596C22.153 8.66234 21.6796 14.9761 17.4933 18.5785ZM9.56035 10.8929L6.52839 13.502L7.83292 15.018L10.8649 12.4089L13.4739 15.4408L14.9899 14.1363L12.3809 11.1044L15.4128 8.49529L14.1083 6.97931L11.0763 9.58838L8.46726 6.55642L6.95128 7.86095L9.56035 10.8929Z"
        fill="#B9BCC3"
      />
    </svg>
  );
}
