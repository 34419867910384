import { PermissionActions, UserRoles } from "../utils/const";

export const IS_PAGES = {
  tutors: "tutors",
  parents: "parents",
  students: "students",
  matching: "matching",
  establishments: "establishments",
  sessions: "sessions",
  crReports: "crReports",
  scReports: "scReports",
  scholarYear: "scholarYear",
  schoolZones: "schoolZones",
  publicHolidays: "publicHolidays",
  availability: "availability",
  sectors: "sectors",
  administrators: "administrators",
  managers: "managers",
  coordinators: "coordinators",
  vsc: "vsc",
};

type AccessPageList = {
  [page: string]: {
    [action: string]: UserRoles[];
  };
};

// MANAGE
const manage = {
  [IS_PAGES.tutors]: {
    [PermissionActions.CREATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.UPDATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.DELETE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
  },
  [IS_PAGES.parents]: {
    [PermissionActions.CREATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.UPDATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.DELETE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
  },
  [IS_PAGES.students]: {
    [PermissionActions.CREATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.UPDATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.DELETE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
  },
  [IS_PAGES.matching]: {
    [PermissionActions.CREATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.UPDATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.DELETE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
  },
  [IS_PAGES.establishments]: {
    [PermissionActions.CREATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.UPDATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.DELETE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
  },
};

const tutoring = {
  [IS_PAGES.sessions]: {
    [PermissionActions.CREATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.UPDATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.DELETE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
  },
  [IS_PAGES.crReports]: {
    [PermissionActions.CREATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.UPDATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.DELETE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
  },
  [IS_PAGES.scReports]: {
    [PermissionActions.CREATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.UPDATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
      UserRoles.VSC,
    ],
    [PermissionActions.DELETE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
  },
};

const setup = {
  [IS_PAGES.scholarYear]: {
    [PermissionActions.CREATE]: [UserRoles.ADMIN],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.UPDATE]: [UserRoles.ADMIN],
    [PermissionActions.DELETE]: [UserRoles.ADMIN],
  },
  [IS_PAGES.schoolZones]: {
    [PermissionActions.CREATE]: [UserRoles.ADMIN],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.UPDATE]: [UserRoles.ADMIN],
    [PermissionActions.DELETE]: [UserRoles.ADMIN],
  },
  [IS_PAGES.publicHolidays]: {
    [PermissionActions.CREATE]: [UserRoles.ADMIN],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.UPDATE]: [UserRoles.ADMIN],
    [PermissionActions.DELETE]: [UserRoles.ADMIN],
  },
  [IS_PAGES.availability]: {
    [PermissionActions.CREATE]: [UserRoles.ADMIN],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.UPDATE]: [UserRoles.ADMIN],
    [PermissionActions.DELETE]: [UserRoles.ADMIN],
  },
  [IS_PAGES.sectors]: {
    [PermissionActions.CREATE]: [UserRoles.ADMIN, UserRoles.COORDINATOR],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    // [PermissionActions.UPDATE]: [UserRoles.ADMIN, UserRoles.COORDINATOR],
    // [PermissionActions.DELETE]: [UserRoles.ADMIN, UserRoles.COORDINATOR],
  },
};

const profilesAndRoles = {
  [IS_PAGES.administrators]: {
    [PermissionActions.CREATE]: [UserRoles.ADMIN],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.UPDATE]: [UserRoles.ADMIN],
    [PermissionActions.DELETE]: [UserRoles.ADMIN],
  },
  [IS_PAGES.managers]: {
    [PermissionActions.CREATE]: [UserRoles.ADMIN],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.UPDATE]: [UserRoles.ADMIN],
    [PermissionActions.DELETE]: [UserRoles.ADMIN],
  },
  [IS_PAGES.coordinators]: {
    [PermissionActions.CREATE]: [UserRoles.ADMIN, UserRoles.MANAGER],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.UPDATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.DELETE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
  },
  [IS_PAGES.vsc]: {
    [PermissionActions.CREATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.READ]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.UPDATE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
    [PermissionActions.DELETE]: [
      UserRoles.ADMIN,
      UserRoles.MANAGER,
      UserRoles.COORDINATOR,
    ],
  },
};

export const accessPageList: any = {
  // MANAGE
  ...manage,
  ...tutoring,
  ...setup,
  ...profilesAndRoles,
};
