export default function VideoCameraIcon(props: any) {
  return (
    <svg
      {...props}
      width={props.width || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3C1.89543 3 1 3.89543 1 5V11C1 12.1046 1.89543 13 3 13H8.5C9.6046 13 10.5 12.1046 10.5 11V5C10.5 3.89543 9.6046 3 8.5 3H3Z"
        fill="#FF6B43"
      />
      <path
        d="M13.2929 12.5L11.5 10.7071V5.29288L13.2929 3.49998C13.9229 2.87001 15 3.31618 15 4.20709V11.7929C15 12.6838 13.9229 13.1299 13.2929 12.5Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
