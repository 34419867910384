export default function LevelIcon(props: any) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79987 1.87019C7.92747 1.81443 8.0726 1.81443 8.2002 1.87019C10.6795 2.95341 13.0291 4.27758 15.2197 5.81304C15.3782 5.92415 15.4583 6.1168 15.4254 6.30757C15.3925 6.49833 15.2523 6.65294 15.0657 6.70447C14.6091 6.83047 14.1565 6.96627 13.7079 7.11087C11.8871 7.69807 10.1345 8.43693 8.4648 9.31253L8.4626 9.31367C8.38687 9.3534 8.31133 9.39333 8.236 9.4336C8.08873 9.51227 7.91193 9.51227 7.76473 9.4336C7.68867 9.393 7.6124 9.3526 7.53593 9.31253C6.71033 8.87953 5.86439 8.48007 5 8.11573V7.96667C5 7.87907 5.04495 7.80133 5.11449 7.75927C6.1179 7.15133 7.15293 6.59043 8.21647 6.07957C8.4654 5.96001 8.57027 5.6613 8.45073 5.41239C8.33113 5.16347 8.0324 5.05861 7.78353 5.17817C6.69067 5.70309 5.62724 6.27939 4.59633 6.90393C4.29407 7.08707 4.09088 7.38673 4.02405 7.72253C3.4541 7.50327 2.87666 7.29907 2.29219 7.1106C1.84364 6.96593 1.39096 6.83047 0.93436 6.70447C0.747747 6.65293 0.607641 6.49833 0.574693 6.30756C0.541746 6.11679 0.621883 5.92415 0.780407 5.81303C2.97097 4.27757 5.32065 2.95341 7.79987 1.87019Z"
        fill="#FF6B43"
      />
      <path
        d="M8.70735 10.3157C10.3332 9.44695 12.0415 8.71255 13.8173 8.12715C13.9068 9.07035 13.964 10.023 13.9876 10.9838C13.9927 11.1899 13.8707 11.378 13.6804 11.4575C11.7796 12.251 9.97168 13.2228 8.27735 14.3519C8.10948 14.4638 7.89075 14.4638 7.72282 14.3519C6.02853 13.2228 4.22057 12.251 2.31982 11.4575C2.12952 11.378 2.00752 11.1899 2.0126 10.9838C2.03628 10.0229 2.09342 9.07022 2.18293 8.12695C2.79693 8.32929 3.40285 8.54955 4.00008 8.78695V9.63389C3.70118 9.80675 3.50008 10.13 3.50008 10.5001C3.50008 10.8303 3.66014 11.1232 3.9069 11.3052C3.84722 11.559 3.7589 11.8073 3.64193 12.0451C3.94372 12.1875 4.24294 12.3346 4.53948 12.486C4.70837 12.1426 4.83083 11.782 4.90684 11.4139C5.25641 11.2581 5.50008 10.9076 5.50008 10.5001C5.50008 10.13 5.29898 9.80675 5.00008 9.63389V9.20402C5.78102 9.54522 6.54598 9.91629 7.29355 10.3157C7.73528 10.5517 8.26562 10.5517 8.70735 10.3157Z"
        fill="#FF6B43"
      />
      <path
        d="M2.97481 12.9748C3.25424 12.6954 3.47668 12.3807 3.64185 12.0449C3.94364 12.1873 4.24285 12.3344 4.5394 12.4859C4.32703 12.9178 4.04109 13.3227 3.68192 13.6819C3.48666 13.8771 3.17008 13.8771 2.97481 13.6819C2.77955 13.4867 2.77955 13.1701 2.97481 12.9748Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
