const handleLanguage = () => {
  const lg = process.env.REACT_APP_LANGUAGE;
  const language = localStorage.getItem("language");

  if (language === null) {
    //@ts-ignore
    localStorage.setItem("language", lg);
    return lg;
  } else return language;
};

const themeConfig = {
  version: "3.1",
  theme: "light",
  contentWidth: "full",
  sidebarCollapsed: false,
  sidebarCollapseButton: true,
  layout: "VerticalLayout",
  navigationFull: false,
  navigationBg: false,
  direction: "ltr",
  language: handleLanguage(),
};

export default themeConfig;
