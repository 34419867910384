export default function QuestionMarkIcon(props: any) {
  return (
    <svg
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.23242 5.2657C8.20875 4.41143 9.79167 4.41143 10.7679 5.2657C11.7442 6.11997 11.7442 7.505 10.7679 8.35933C10.598 8.508 10.4097 8.63075 10.2094 8.72775C9.588 9.02842 9.00017 9.55967 9.00017 10.25V10.875M16.5 9C16.5 13.1422 13.1422 16.5 9 16.5C4.85787 16.5 1.5 13.1422 1.5 9C1.5 4.85787 4.85787 1.5 9 1.5C13.1422 1.5 16.5 4.85787 16.5 9ZM9 13.375H9.00625V13.3812H9V13.375Z"
        stroke="#313236"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
