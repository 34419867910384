import { Button, Col, Row } from "antd";
import { RiEqualizerLine } from "react-icons/ri";
import { FormattedMessage } from "react-intl";
import { ExportCsvIcon } from "../icons";
import DownloadCsv from "./DownloadCsv";

export default function FilterHeader(props: any) {
  const {
    loading,
    loadingCsv,
    expanded,
    setExpanded,
    download,
    hideExportCsv = false,
    disabled = false,
  } = props;

  return (
    <Row align="middle" justify="space-between">
      <Col className={`${loading || disabled ? "disabled opacity-50" : ""}`}>
        <Button
          onClick={() => setExpanded(!expanded)}
          icon={
            <RiEqualizerLine className="remix-icon hp-text-color-black-60 mr-8" />
          }
          size="small"
          className={`btn-gray ${
            expanded ? "btn-transparent" : ""
          } btn-shadow hp-mb-20`}
        >
          <FormattedMessage id="filters" />
        </Button>
      </Col>
      <DownloadCsv
        hideExportCsv={hideExportCsv}
        loadingCsv={loadingCsv}
        disabled={disabled}
        download={download}
      />
    </Row>
  );
}
