export default function ChildrensIcon(props: any) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9998 15.5993C15.206 15.6163 15.4147 15.625 15.6254 15.625C16.499 15.625 17.3377 15.4757 18.1174 15.2011C18.1226 15.1348 18.1254 15.0677 18.1254 15C18.1254 13.6192 17.006 12.5 15.6254 12.5C15.1024 12.5 14.6168 12.6606 14.2155 12.9352M14.9998 15.5993C14.9999 15.6078 14.9999 15.6164 14.9999 15.625C14.9999 15.8125 14.9895 15.9976 14.9695 16.1797C13.5055 17.0197 11.8088 17.5 9.99988 17.5C8.19099 17.5 6.49427 17.0197 5.0303 16.1797C5.0102 15.9976 4.99988 15.8125 4.99988 15.625C4.99988 15.6164 4.9999 15.6079 4.99995 15.5993M14.9998 15.5993C14.9949 14.6188 14.7077 13.7051 14.2155 12.9352M14.2155 12.9352C13.3272 11.546 11.7711 10.625 9.99988 10.625C8.22887 10.625 6.67292 11.5457 5.78462 12.9347M5.78462 12.9347C5.3834 12.6604 4.89818 12.5 4.37549 12.5C2.99478 12.5 1.87549 13.6192 1.87549 15C1.87549 15.0677 1.87818 15.1348 1.88345 15.2011C2.66315 15.4757 3.50188 15.625 4.37549 15.625C4.58577 15.625 4.79404 15.6163 4.99995 15.5993M5.78462 12.9347C5.29214 13.7047 5.00486 14.6187 4.99995 15.5993M12.4999 5.625C12.4999 7.00571 11.3806 8.125 9.99988 8.125C8.61913 8.125 7.49988 7.00571 7.49988 5.625C7.49988 4.24429 8.61913 3.125 9.99988 3.125C11.3806 3.125 12.4999 4.24429 12.4999 5.625ZM17.4999 8.125C17.4999 9.1605 16.6604 10 15.6249 10C14.5894 10 13.7499 9.1605 13.7499 8.125C13.7499 7.08947 14.5894 6.25 15.6249 6.25C16.6604 6.25 17.4999 7.08947 17.4999 8.125ZM6.24988 8.125C6.24988 9.1605 5.41041 10 4.37488 10C3.33935 10 2.49988 9.1605 2.49988 8.125C2.49988 7.08947 3.33935 6.25 4.37488 6.25C5.41041 6.25 6.24988 7.08947 6.24988 8.125Z"
        stroke="#323136"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
