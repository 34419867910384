export default function GroupOfPeopleGreenIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.875 5.625C6.875 3.89911 8.27411 2.5 10 2.5C11.7259 2.5 13.125 3.89911 13.125 5.625C13.125 7.35089 11.7259 8.75 10 8.75C8.27411 8.75 6.875 7.35089 6.875 5.625Z"
        fill="#71A850"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.125 8.125C13.125 6.74429 14.2442 5.625 15.625 5.625C17.0057 5.625 18.125 6.74429 18.125 8.125C18.125 9.50575 17.0057 10.625 15.625 10.625C14.2442 10.625 13.125 9.50575 13.125 8.125Z"
        fill="#71A850"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.875 8.125C1.875 6.74429 2.99429 5.625 4.375 5.625C5.75571 5.625 6.875 6.74429 6.875 8.125C6.875 9.50575 5.75571 10.625 4.375 10.625C2.99429 10.625 1.875 9.50575 1.875 8.125Z"
        fill="#71A850"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25825 12.5979C6.25656 11.037 8.00699 10 10 10C11.9933 10 13.7439 11.0373 14.7422 12.5985C15.4323 13.6778 15.7309 14.9793 15.5909 16.2483C15.5689 16.4466 15.4537 16.6225 15.2807 16.7218C13.7245 17.6147 11.9209 18.125 10 18.125C8.07913 18.125 6.27552 17.6147 4.71941 16.7218C4.54636 16.6225 4.43111 16.4466 4.40922 16.2483C4.26914 14.9791 4.56787 13.6774 5.25825 12.5979Z"
        fill="#71A850"
      />
      <path
        d="M4.23523 11.8782C4.2252 11.8936 4.21523 11.909 4.20532 11.9245C3.40076 13.1825 3.03257 14.6807 3.14559 16.1576C2.63889 16.0807 2.14725 15.9568 1.67536 15.7907L1.57956 15.7569C1.4048 15.6953 1.28269 15.5367 1.26799 15.3519L1.25993 15.2507C1.25335 15.1679 1.25 15.0843 1.25 15.0001C1.25 13.321 2.57421 11.9513 4.23523 11.8782Z"
        fill="#71A850"
      />
      <path
        d="M16.8547 16.1576C16.9677 14.6809 16.5997 13.1831 15.7955 11.9253C15.7854 11.9095 15.7753 11.8938 15.7651 11.8782C17.426 11.9514 18.7501 13.3212 18.7501 15.0001C18.7501 15.0843 18.7467 15.1679 18.7401 15.2507L18.7321 15.3519C18.7174 15.5367 18.5952 15.6953 18.4205 15.7569L18.3247 15.7907C17.8529 15.9568 17.3614 16.0806 16.8547 16.1576Z"
        fill="#71A850"
      />
    </svg>
  );
}
