export default function MailIcon(props: any) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.7793V11.4999C1 12.6045 1.89543 13.4999 3 13.4999H13C14.1046 13.4999 15 12.6045 15 11.4999V5.7793L9.0482 9.44196C8.4054 9.8375 7.5946 9.8375 6.9518 9.44196L1 5.7793Z"
        fill="#FF6B43"
      />
      <path
        d="M15 4.60522V4.5C15 3.39543 14.1046 2.5 13 2.5H3C1.89543 2.5 1 3.39543 1 4.5V4.60522L7.47593 8.5904C7.79733 8.7882 8.20267 8.7882 8.52407 8.5904L15 4.60522Z"
        fill="#FF6B43"
      />
    </svg>
  );
}
