export default function ChipIcon(props: any) {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.875 2.5V3.75M3.75 6.875H2.5M17.5 6.875H16.25M3.75 10H2.5M17.5 10H16.25M3.75 13.125H2.5M17.5 13.125H16.25M6.875 16.25V17.5M10 2.5V3.75M10 16.25V17.5M13.125 2.5V3.75M13.125 16.25V17.5M5.625 16.25H14.375C15.4105 16.25 16.25 15.4105 16.25 14.375V5.625C16.25 4.58947 15.4105 3.75 14.375 3.75H5.625C4.58947 3.75 3.75 4.58947 3.75 5.625V14.375C3.75 15.4105 4.58947 16.25 5.625 16.25ZM6.25 6.25H13.75V13.75H6.25V6.25Z"
        stroke="#FF6B43"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
