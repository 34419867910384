export default function PresenceTableIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.875 1.875C1.52983 1.875 1.25 2.15483 1.25 2.5C1.25 2.84517 1.52983 3.125 1.875 3.125H2.5V11.875C2.5 13.2557 3.61929 14.375 5 14.375H6.00786L5.03207 17.3023C4.92292 17.6298 5.09989 17.9837 5.42736 18.0929C5.75482 18.2021 6.10878 18.0251 6.21793 17.6977L6.49214 16.875H13.5078L13.7821 17.6977C13.8912 18.0251 14.2452 18.2021 14.5727 18.0929C14.9001 17.9837 15.0771 17.6298 14.9679 17.3023L13.9922 14.375H15C16.3807 14.375 17.5 13.2557 17.5 11.875V3.125H18.125C18.4702 3.125 18.75 2.84517 18.75 2.5C18.75 2.15483 18.4702 1.875 18.125 1.875H1.875ZM7.32548 14.375H12.6745L13.0912 15.625H6.90881L7.32548 14.375ZM14.0629 6.87889C14.3617 6.70606 14.4638 6.32372 14.291 6.02493C14.1182 5.72615 13.7358 5.62405 13.4371 5.79688C12.2981 6.45573 11.2931 7.31976 10.4722 8.33842L9.19192 7.05813C8.94783 6.81405 8.55217 6.81405 8.30806 7.05813L5.80806 9.55817C5.56398 9.80217 5.56398 10.1979 5.80806 10.442C6.05213 10.6861 6.44787 10.6861 6.69194 10.442L8.75 8.38392L10.0977 9.73167C10.2285 9.8625 10.4106 9.92858 10.5948 9.91225C10.7791 9.89592 10.9467 9.79883 11.0524 9.64708C11.8392 8.51808 12.8678 7.57023 14.0629 6.87889Z"
        fill="#FF0022"
      />
    </svg>
  );
}
