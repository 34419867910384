export default function ReportsIcon(props: any) {
  return (
    <svg
      {...props}
      width={props.width || 16}
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="file-text"
      fill="#FF6B43"
      aria-hidden="true"
    >
      <path d="M854.6 288.7c6 6 9.4 14.1 9.4 22.6V928c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32h424.7c8.5 0 16.7 3.4 22.7 9.4l215.2 215.3zM790.2 326L602 137.8V326h188.2zM320 482a8 8 0 00-8 8v48a8 8 0 008 8h384a8 8 0 008-8v-48a8 8 0 00-8-8H320zm0 136a8 8 0 00-8 8v48a8 8 0 008 8h184a8 8 0 008-8v-48a8 8 0 00-8-8H320z"></path>
    </svg>
  );
}
