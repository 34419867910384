import { lazy } from "react";
import { RedirectPaths, UserRoles } from "../../utils/const";

const ParentPages = [
  {
    path: "/",
    component: lazy(() => import("../../view/parent/dashboard/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.PARENT_LOGIN,
  },
  {
    path: `/${UserRoles.PARENT}/on-boarding`,
    component: lazy(() => import("../../view/parent/onboarding/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.PARENT_LOGIN,
  },
  {
    path: `/${UserRoles.PARENT}/children`,
    component: lazy(() => import("../../view/parent/children/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.PARENT_LOGIN,
  },
  {
    path: `/${UserRoles.PARENT}/sessions`,
    component: lazy(() => import("../../view/parent/sessions/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.PARENT_LOGIN,
  },
  {
    path: `/${UserRoles.PARENT}/account`,
    component: lazy(() => import("../../view/parent/account/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.PARENT_LOGIN,
  },
  {
    path: `/${UserRoles.PARENT}/equipment`,
    component: lazy(() => import("../../view/parent/equipment/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.PARENT_LOGIN,
  },
  {
    path: `/${UserRoles.PARENT}/contact-zupdeco`,
    component: lazy(() => import("../../view/parent/contact/index")),
    layout: "VerticalLayout",
    redirectPath: RedirectPaths.PARENT_LOGIN,
  },
];

export default ParentPages;
