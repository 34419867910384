import { FormattedMessage } from "react-intl";

import {
  AcademicHatIcon,
  ManageIcon,
  UsersIcon,
  TutoringIcon,
} from "../../view/icons";

import {
  ISManagePaths,
  ISProfileRolesPaths,
  ISSetupPaths,
  ISTutoringPaths,
} from "../../utils/const";

const AdminMenu = [
  {
    id: "manage",
    title: (
      <span style={{ fontWeight: "500" }}>
        <FormattedMessage id="manage" />
      </span>
    ),
    icon: <ManageIcon />,
    children: [
      {
        id: "tutors",
        title: <FormattedMessage id="tutors" />,
        navLink: ISManagePaths.TUTORS,
      },
      {
        id: "parents",
        title: <FormattedMessage id="parents" />,
        navLink: ISManagePaths.PARENTS,
      },
      {
        id: "students",
        title: <FormattedMessage id="pupils" />,
        navLink: ISManagePaths.STUDENTS,
      },
      {
        id: "matching",
        title: <FormattedMessage id="matching" />,
        navLink: ISManagePaths.MATCHING,
      },
      {
        id: "establishments",
        title: <FormattedMessage id="establishments" />,
        navLink: ISManagePaths.ESTABLISHMENTS,
      },
      {
        id: "vsc",
        title: <FormattedMessage id="vsc" />,
        navLink: ISManagePaths.VSC,
      },
    ],
  },
  {
    id: "tutoring",
    title: (
      <span style={{ fontWeight: "500" }}>
        <FormattedMessage id="tutoring" />
      </span>
    ),
    icon: <TutoringIcon />,
    children: [
      {
        id: "sessions",
        title: <FormattedMessage id="sessions" />,
        navLink: ISTutoringPaths.SESSIONS,
      },
      {
        id: "cr-reports",
        title: <FormattedMessage id="cr-reports" />,
        navLink: ISTutoringPaths.CR_REPORTS,
      },
      {
        id: "sc-reports",
        title: <FormattedMessage id="sc-reports" />,
        navLink: ISTutoringPaths.SC_REPORTS,
      },
    ],
  },
  {
    id: "setup",
    title: (
      <span style={{ fontWeight: "500" }}>
        <FormattedMessage id="setup" />
      </span>
    ),
    icon: <AcademicHatIcon />,
    children: [
      {
        id: "scholar-year",
        title: <FormattedMessage id="scholar-year" />,
        navLink: ISSetupPaths.SCHOLAR_YEAR,
      },
      {
        id: "school-zones",
        title: <FormattedMessage id="school-zones" />,
        navLink: ISSetupPaths.SCHOOL_ZONES,
      },
      {
        id: "public-holidays",
        title: <FormattedMessage id="public-holidays" />,
        navLink: ISSetupPaths.PUBLIC_HOLIDAYS,
      },
      {
        id: "availability",
        title: <FormattedMessage id="availabilities" />,
        navLink: ISSetupPaths.AVAILABILITY,
      },
      // {
      //   id: "sectors",
      //   title: <FormattedMessage id="sectors" />,
      //   navLink: ISSetupPaths.SECTORS,
      // },
    ],
  },
  {
    id: "profile",
    title: (
      <span style={{ fontWeight: "500" }}>
        <FormattedMessage id="profile-and-roles" />
      </span>
    ),
    icon: <UsersIcon />,
    children: [
      {
        id: "administrators",
        title: <FormattedMessage id="administrators" />,
        navLink: ISProfileRolesPaths.ADMINISTRATORS,
      },
      {
        id: "managers",
        title: <FormattedMessage id="managers" />,
        navLink: ISProfileRolesPaths.MANAGERS,
      },
      {
        id: "coordinators",
        title: <FormattedMessage id="coordinators" />,
        navLink: ISProfileRolesPaths.COORDINATORS,
      },
    ],
  },
];

export default AdminMenu;
