export default function ExportCsvIcon(props: any) {
  return (
    <svg
      {...props}
      className="remix-icon hp-text-color-black-60 hp-mr-8"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3279 11.875V9.6875C17.3279 8.1342 16.0687 6.875 14.5154 6.875H13.2654C12.7476 6.875 12.3279 6.45527 12.3279 5.9375V4.6875C12.3279 3.1342 11.0687 1.875 9.51538 1.875H7.95288M5.76538 18.125H16.3904C16.9081 18.125 17.3279 17.7052 17.3279 17.1875V9.375C17.3279 5.23287 13.97 1.875 9.82788 1.875H5.76538C5.24761 1.875 4.82788 2.29473 4.82788 2.8125V9"
        stroke="#111214"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.618 16.077C4.072 16.077 3.603 15.972 3.211 15.762C2.819 15.552 2.518 15.2557 2.308 14.873C2.10267 14.4903 2 14.0423 2 13.529C2 13.1463 2.05833 12.7987 2.175 12.486C2.29633 12.1733 2.469 11.905 2.693 11.681C2.92167 11.457 3.197 11.2867 3.519 11.17C3.84567 11.0487 4.212 10.988 4.618 10.988C4.83267 10.988 5.05433 11.0137 5.283 11.065C5.51633 11.1117 5.72167 11.184 5.899 11.282C6.02967 11.352 6.12067 11.4407 6.172 11.548C6.22333 11.6553 6.23967 11.765 6.221 11.877C6.207 11.989 6.16733 12.0893 6.102 12.178C6.03667 12.2667 5.95267 12.3273 5.85 12.36C5.74733 12.388 5.63533 12.3717 5.514 12.311C5.374 12.2457 5.234 12.1967 5.094 12.164C4.95867 12.1267 4.81633 12.108 4.667 12.108C4.37767 12.108 4.135 12.164 3.939 12.276C3.74767 12.3833 3.603 12.542 3.505 12.752C3.407 12.962 3.358 13.221 3.358 13.529C3.358 13.837 3.407 14.0983 3.505 14.313C3.603 14.523 3.74767 14.684 3.939 14.796C4.135 14.9033 4.37767 14.957 4.667 14.957C4.78833 14.957 4.919 14.943 5.059 14.915C5.199 14.8823 5.33667 14.8333 5.472 14.768C5.612 14.7073 5.73567 14.691 5.843 14.719C5.955 14.747 6.04367 14.803 6.109 14.887C6.179 14.971 6.22333 15.069 6.242 15.181C6.26067 15.2883 6.24433 15.3957 6.193 15.503C6.14633 15.6103 6.06233 15.6967 5.941 15.762C5.77767 15.86 5.57467 15.937 5.332 15.993C5.094 16.049 4.856 16.077 4.618 16.077Z"
        fill="black"
      />
      <path
        d="M8.58629 16.077C8.39962 16.077 8.20362 16.063 7.99829 16.035C7.79762 16.0117 7.60629 15.9743 7.42429 15.923C7.24229 15.8717 7.07896 15.811 6.93429 15.741C6.80829 15.6803 6.71962 15.601 6.66829 15.503C6.61696 15.4003 6.59829 15.293 6.61229 15.181C6.62629 15.069 6.66596 14.971 6.73129 14.887C6.79662 14.7983 6.88296 14.74 6.99029 14.712C7.09762 14.6793 7.21896 14.6933 7.35429 14.754C7.53162 14.838 7.73229 14.9033 7.95629 14.95C8.18029 14.9967 8.39029 15.02 8.58629 15.02C8.89429 15.02 9.10896 14.9803 9.23029 14.901C9.35629 14.817 9.41929 14.7143 9.41929 14.593C9.41929 14.4857 9.37496 14.3993 9.28629 14.334C9.20229 14.2687 9.05062 14.2127 8.83129 14.166L8.01229 13.991C7.56429 13.8977 7.23062 13.7343 7.01129 13.501C6.79196 13.2677 6.68229 12.9667 6.68229 12.598C6.68229 12.3553 6.73129 12.136 6.82929 11.94C6.93196 11.7393 7.07429 11.569 7.25629 11.429C7.44296 11.289 7.66229 11.1817 7.91429 11.107C8.17096 11.0277 8.45562 10.988 8.76829 10.988C9.01096 10.988 9.25596 11.016 9.50329 11.072C9.75529 11.1233 9.97229 11.2027 10.1543 11.31C10.2616 11.366 10.3386 11.443 10.3853 11.541C10.432 11.639 10.4506 11.7417 10.4413 11.849C10.432 11.9517 10.3946 12.0427 10.3293 12.122C10.2686 12.2013 10.1846 12.255 10.0773 12.283C9.97462 12.3063 9.85096 12.2877 9.70629 12.227C9.57096 12.1663 9.41696 12.122 9.24429 12.094C9.07629 12.0613 8.91296 12.045 8.75429 12.045C8.58162 12.045 8.43462 12.066 8.31329 12.108C8.19196 12.1453 8.09862 12.2013 8.03329 12.276C7.97262 12.3507 7.94229 12.437 7.94229 12.535C7.94229 12.633 7.98196 12.717 8.06129 12.787C8.14529 12.8523 8.29696 12.9083 8.51629 12.955L9.32829 13.13C9.78096 13.228 10.1193 13.389 10.3433 13.613C10.5673 13.837 10.6793 14.1287 10.6793 14.488C10.6793 14.7307 10.6303 14.95 10.5323 15.146C10.4343 15.342 10.2943 15.51 10.1123 15.65C9.93029 15.7853 9.71096 15.8903 9.45429 15.965C9.19762 16.0397 8.90829 16.077 8.58629 16.077Z"
        fill="black"
      />
      <path
        d="M13.3022 16.063C13.1248 16.063 12.9802 16.0257 12.8682 15.951C12.7608 15.8763 12.6698 15.7573 12.5952 15.594L10.9012 11.856C10.8265 11.6927 10.8032 11.5457 10.8312 11.415C10.8592 11.2843 10.9245 11.184 11.0272 11.114C11.1298 11.0393 11.2582 11.002 11.4122 11.002C11.6035 11.002 11.7458 11.044 11.8392 11.128C11.9372 11.212 12.0212 11.3357 12.0912 11.499L13.5192 14.803H13.1272L14.5552 11.492C14.6252 11.3287 14.7092 11.2073 14.8072 11.128C14.9052 11.044 15.0428 11.002 15.2202 11.002C15.3648 11.002 15.4862 11.0393 15.5842 11.114C15.6822 11.184 15.7428 11.2843 15.7662 11.415C15.7942 11.5457 15.7708 11.6927 15.6962 11.856L13.9952 15.594C13.9252 15.7573 13.8365 15.8763 13.7292 15.951C13.6218 16.0257 13.4795 16.063 13.3022 16.063Z"
        fill="black"
      />
    </svg>
  );
}
