import { Button } from "antd";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { TrashIcon } from "../icons";
import { useAccessControl } from "../../hooks/useAccessControl";
import { PermissionActions } from "../../utils/const";

type Props = {
  page: string;
  formHanlder: () => void;
  link: string;
  loading: boolean;
  isLoading: boolean;
  setPopupModalVisible: any;
  allowDelete?: boolean;
  allowCancel?: boolean;
  allowSave?: boolean;
};

export default function PageEditButtons({
  page,
  formHanlder,
  link,
  setPopupModalVisible,
  loading,
  isLoading,
  allowDelete = true,
  allowCancel = true,
  allowSave = true,
}: Props) {
  const hasUpdateAccess = useAccessControl(page, PermissionActions.UPDATE);
  const hasDeleteAccess = useAccessControl(page, PermissionActions.DELETE);

  return (
    <div
      className={
        loading || isLoading
          ? "hp-d-flex hp-justify-content-end disabled opacity-65"
          : "hp-d-flex hp-justify-content-end"
      }
    >
      {allowDelete && hasDeleteAccess && (
        <Button
          icon={
            <TrashIcon className="mr-8 remix-icon hp-text-color-danger-1" />
          }
          type="primary"
          className="hp-bg-black-0 hp-border-color-black-40 hp-text-color-danger-1 hp-px-20 hp-mr-12"
          onClick={() => setPopupModalVisible(true)}
        >
          <FormattedMessage id="delete" />
        </Button>
      )}

      {allowCancel && (
        <Link to={link}>
          <Button
            type="primary"
            className="hp-bg-black-0 hp-border-color-black-40 hp-text-color-black-100 hp-px-20 hp-mr-12"
          >
            <FormattedMessage id="cancel" />
          </Button>
        </Link>
      )}

      {allowSave && hasUpdateAccess && (
        <Button
          type="primary"
          className="hp-bg-primary-1 hp-border-color-primary-1 hp-px-20"
          onClick={formHanlder}
          loading={loading}
        >
          <FormattedMessage id="save-changes" />
        </Button>
      )}
    </div>
  );
}
