export default function MatchingIcon(props: any) {
  return (
    <svg
      {...props}
      width={props.width || 20}
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 25 25"
      viewBox="0 0 25 25"
    >
      <path
        d="M23 16.44H5.62l2.49-2.49c.59-.59.59-1.54 0-2.12s-1.54-.59-2.12 0l-5.05 5.05c-.07.07-.13.15-.19.23-.01.02-.02.04-.03.05-.04.07-.08.13-.11.2C.6 17.4.6 17.42.59 17.45c-.02.06-.05.13-.06.2-.02.1-.03.2-.03.3s.01.2.03.3c.01.07.04.13.06.19.01.03.02.06.03.09.03.07.07.14.11.2.01.02.02.04.03.06.05.08.12.16.19.23l5.05 5.05c.29.29.68.44 1.06.44s.77-.15 1.06-.44c.59-.59.59-1.54 0-2.12l-2.49-2.5H23c.83 0 1.5-.67 1.5-1.5S23.83 16.44 23 16.44zM24.39 6.49C24.31 6.3 24.2 6.13 24.06 6l-5.05-5.06c-.59-.59-1.54-.59-2.12 0s-.59 1.54 0 2.12l2.49 2.5H2c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h17.38l-2.49 2.49c-.59.59-.59 1.54 0 2.12.29.29.68.44 1.06.44s.77-.15 1.06-.44l5.05-5.05c.14-.14.25-.31.33-.49 0-.01 0-.02.01-.02.07-.17.11-.35.11-.55s-.04-.38-.11-.55C24.39 6.5 24.39 6.49 24.39 6.49z"
        fill="#FF6B43"
        className="color000 svgShape"
      />
    </svg>
  );
}
