export const formattedAddress = (
  address: string,
  postalCode: string,
  city: string,
  country: string,
) => {
  const parts = [];
  let addComa = true;
  let addHyphen = true;
  const fullAddress: any =
    (address || "") + (address && postalCode ? " " + postalCode : "");

  if (!address || !city) {
    addComa = false;
  }

  if (!address && !postalCode && !city) {
    addHyphen = false;
  }

  if (fullAddress) {
    parts.push(fullAddress);
  }

  if (city) {
    if (addComa) {
      parts.push(", ");
    }

    parts.push(city);
  }

  if (country) {
    if (addHyphen) {
      parts.push(" - ");
    }

    parts.push(country);
  }

  return parts.join("");
};
