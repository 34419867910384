import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  RecaptchaVerifier,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
export const firebase = initializeApp(firebaseConfig);
export const auth = getAuth(firebase);
export const googleProvider = new GoogleAuthProvider();
googleProvider.addScope("email"); // Adding email scope
googleProvider.addScope("profile");
export const facebookProvide = new FacebookAuthProvider();

export const generateReCaptcha = (captchaId) => {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier(
      captchaId,
      {
        size: "invisible",
        callback: (response) => {
          console.log("recaptcha resolved", response);
        },
      },
      auth
    );
  }
};
