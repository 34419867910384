import { RiCloseCircleLine } from "react-icons/ri";
import { Console } from "./";

export const StopVideoAndInputStream = async (
  videoTracks: any,
  audioTracks: any,
  setActiveCamera: any,
  setActiveMicrophone: any,
  message: any,
  intl: any,
) => {
  try {
    if (videoTracks !== null) {
      if (videoTracks.length !== 0)
        videoTracks.forEach((track: any) => {
          track.stop();
          track.enabled = false;
          track.active = false;
        });
    }

    if (audioTracks !== null) {
      if (audioTracks.length !== 0)
        audioTracks.forEach((track: any) => {
          track.stop();
          track.enabled = false;
          track.active = false;
        });
    }

    setActiveCamera(null);
    setActiveMicrophone(null);
  } catch (err) {
    Console(err);

    message.error({
      content: intl.formatMessage({
        id: "something-went-wrong-please-reload-the-page",
      }),
      icon: <RiCloseCircleLine className="remix-icon" />,
    });
  }
};
