import MenuLogo from "../../layout/components/menu/logo";
import { Col } from "antd";

export default function Header() {
  return (
    <Col
      span={24}
      className="hp-d-flex-full-center hp-border-bottom-1 hp-border-color-dark-60 hp-py-32"
    >
      <MenuLogo />
    </Col>
  );
}
