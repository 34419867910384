export default function AuthWithPhone() {
  return (
    <svg
      className="hp-mr-12"
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 0.5C1.50736 0.5 0.5 1.50736 0.5 2.75V13.25C0.5 14.4927 1.50736 15.5 2.75 15.5H7.25C8.49267 15.5 9.5 14.4927 9.5 13.25V2.75C9.5 1.50736 8.49267 0.5 7.25 0.5H2.75ZM2 2.75C2 2.33579 2.33579 2 2.75 2H3.5V2.25C3.5 2.66421 3.8358 3 4.25 3H5.75C6.1642 3 6.5 2.66421 6.5 2.25V2H7.25C7.6642 2 8 2.33579 8 2.75V13.25C8 13.6642 7.6642 14 7.25 14H2.75C2.33579 14 2 13.6642 2 13.25V2.75Z"
        fill="#313236"
      />
    </svg>
  );
}
